import React from "react";
import "../../style/components/TopicBlock.css";
import BlockSection from "../Block/BlockSection";
import { useTranslation } from "react-i18next";

const TopicBlock = ({ topics = [] }) => {
    const { t } = useTranslation();
    return (
        <BlockSection
            className="author-TopicBlock__section"
            title={t("topic.title")}
            showHelp={true}
            explanation={t("topic.topic_explanation")}
        >
            <table cellSpacing="10px">
                <thead>
                    <tr>
                        <th>{t("topic.weight")}</th>
                        <th style={{ textAlign: "left" }}>
                            {t("topic.categories")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {topics.slice(0, 5).map(({ score, topics }) => {
                        const arr = [...topics];
                        const last = arr.pop();
                        // pop() removes last item of array.
                        // arr now contains original array minus last item
                        return (
                            <tr key={topics.join(" > ")}>
                                <td>{(score * 100).toFixed(1)}%</td>
                                <td title={topics.join(" > ")}>
                                    {/** .join all array items with a > print the
                                    last item in bold */}
                                    {arr.join(" > ")} > <b>{last}</b>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </BlockSection>
    );
};

export default TopicBlock;
