import React, { useState } from "react";

import { halfLevelArray } from "../../config/helpers";
import "../../style/components/ReaderComprehensionGraph.css";

import BlockSection from "../Block/BlockSection";
import { useTranslation } from "react-i18next";

const comprehensionLevel = (textLevel, readerLevel) => {
    const powerVariable = (readerLevel - textLevel) * 1.35;

    const x = Math.E ** powerVariable;

    const y = Math.E ** powerVariable + 1;

    return Math.round((x / y) * 100);
};

const ReaderComprehensionGraph = ({ cefrNumeric, targetLevel }) => {
    const [expand, expandGraph] = useState(false);
    const { t } = useTranslation();

    if (!cefrNumeric) return null;

    return (
        <BlockSection
            className="author-ReaderComprehensionGraph"
            title={t("cefr.reader_comprehension")}
            showHelp={true}
            explanation={t("cefr.reader_comprehension_explanation")}
        >
            <div onClick={() => expandGraph(!expand)} className="toggle">
                {expand ? t("cefr.hide_graph") : t("cefr.show_graph")}
            </div>
            {!expand ? null : (
                <table>
                    <tbody>
                        {halfLevelArray.map(item => {
                            const comprehensionPercentage = comprehensionLevel(
                                cefrNumeric,
                                item.number,
                            );

                            const getClassName =
                                item.number === targetLevel
                                    ? "author-ReaderComprehensionGraph__selected"
                                    : "author-ReaderComprehensionGraph__row";

                            return (
                                <tr key={item.number} className={getClassName}>
                                    <td className="author-ReaderComprehensionGraph__name">
                                        <span> {item.name} </span>
                                    </td>
                                    <ReaderComprehensionBar
                                        comprehensionPercentage={
                                            comprehensionPercentage
                                        }
                                    />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </BlockSection>
    );
};

export default ReaderComprehensionGraph;

const ReaderComprehensionBar = ({ comprehensionPercentage }) => (
    <td className="author-ReaderComprehensionGraph__result">
        <span
            className="author-ReaderComprehensionGraph__bar"
            style={{
                width: comprehensionPercentage + "%",
            }}
        />
        <span className="author-ReaderComprehensionGraph__percentage">
            {comprehensionPercentage + "%"}
        </span>
    </td>
);
