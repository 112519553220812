import React from "react";
import PropTypes from "prop-types";
import "../../style/components/ModalSessionEnded.css";
import { useTranslation } from "react-i18next";

const ModalSessionEnded = props => {
    const { t } = useTranslation();

    if (!props.sessionEnded) return null;
    return (
        <div className="ModalSessionEnded">
            <div>
                <h1>
                    {t("sidebar.session_terminate_warning_text")}{" "}
                    {props.remainingTime}
                </h1>
                <button onClick={props.onSessionTimeout}>
                    {t("sidebar.logout")}
                </button>
                {props.remainingTime > 1 && (
                    <button onClick={props.extendSession}>
                        {t("sidebar.extend_session")}
                    </button>
                )}
            </div>
        </div>
    );
};

ModalSessionEnded.propTypes = {
    remainingTime: PropTypes.number,
    sessionEnded: PropTypes.bool,
    onSessionTimeout: PropTypes.func,
    extendSession: PropTypes.func,
};

export default ModalSessionEnded;
