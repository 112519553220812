import axios from "axios";
import { stringify } from "querystring";
import Alert from "react-s-alert";
import api from "./api";
import { errorCodeMessages, initialState } from "./helpers";

Object.assign(axios.defaults, {
    baseURL: window?.papyrus?.API_HOST,
    withCredentials: true,
    headers: { Cache: "no-cache" },
});

//TEXT FUNCTIONS
export const fetchLinguistics = async (text, targetLevel) => {
    try {
        const response = await axios.post(api.textStatistics(), {
            text,
            targetLevel,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 403) {
            return {};
        }
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }

        Alert.error("failed to get text statistics");
    }
};

export const fetchTopics = async (text, targetLevel) => {
    try {
        const response = await axios.post(api.topics(), { text, targetLevel });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }
        // call can only be done with a certain role
        // clicking analyse will perform all calls though
        // Shouldn't show an error alert if user does not have right role
        if (e.response.status === 403) {
            return { topics: [] };
        }

        Alert.error("failed to get Topics data", "failed to fetch Topics data");
    }
};

export const fetchKeywords = async (text, targetLevel) => {
    try {
        const response = await axios.post(api.keywords(), {
            text,
            targetLevel,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }
        // call can only be done with a certain role
        // clicking analyse will perform all calls though
        // Shouldn't show an error alert if user does not have right role
        if (e.response.status === 403) {
            return {};
        }

        Alert.error("failed to get Keywords", "failed to fetch Keywords");
    }
};

export const fetchCefrLevel = async (classLevel, text, targetLevel) => {
    try {
        const response = await axios.post(api.cefr(classLevel, targetLevel), {
            text,
            targetLevel,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            Alert.info(errorCodeMessages[e.response.status]);
            return;
        }

        Alert.error("failed to get CEFR data", "failed to fetch CEFR data");
    }
};

export const fetchReadability = async (text, targetLevel) => {
    try {
        const response = await axios.post(api.readability(), {
            text,
            targetLevel,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }

        Alert.error("failed to fetch readability data");
    }
};

/**
 * Fetch the GseWordlevel from the backend.
 * debounce ensures we don't fetch too often, which might happen when the user is typing with highlighting on
 * DEV & TST have a timeout of 1 second
 * ACC & PROD have a timeout of 2 seconds
 * @param {String} text The text string to be analysed
 * @param {String} classLevel The predetermined target level of the text
 */

export const fetchWordDifficulty = async (text, classLevel) => {
    try {
        const response = await axios.post(api.wordDifficulty(classLevel), {
            text,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }

        Alert.error("failed to fetch gse word scores");
    }
};

export const fetchLastAnalysedText = async () => {
    try {
        const response = await axios.get(api.lastText());

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 404) {
            return initialState;
        }

        console.error(
            `Error while fetching your last analysed text: failed to fetch last analysed text`,
        );
    }
};

export const fetchPhraseReplacements = async ({
    text,
    classLevel,
    targetLevel,
    startIndex,
    endIndex,
}) => {
    try {
        const response = await axios.post(api.replacePhrase(), {
            text,
            classLevel,
            targetLevel,
            startIndex,
            endIndex,
            nrResults: 10,
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response.status === 429) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }
        if (e.response.status === 413) {
            return Alert.info(errorCodeMessages[e.response.status]);
        }

        Alert.error(`Failed to fetch alternative words: ${e.message}`);
    }
};

//USER FUNCTIONS
export const fetchUser = async () => {
    try {
        const { data } = await axios.get(api.auth.current(), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (!(data && data.userId)) {
            throw new Error("failed");
        }
        return data;
    } catch (e) {
        console.error("failed to fetch user", e.message);
        return false;
    }
};

export const login = async (username, password) => {
    try {
        const response = await axios.post(
            api.auth.login(),
            stringify({ username, password }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        );

        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        if (e.response && e.response.status === 401) {
            Alert.error(`Failed to login: Invalid Credentials`);
        } else {
            Alert.error(`Failed to login: ${e.message}`);
        }

        await logout();
    }
};

export const register = async (
    fullName,
    email,
    password,
    occupation,
    organisation,
    termsOfUseAccepted,
) => {
    try {
        const response = await axios.post(api.auth.register(), {
            fullName,
            email,
            password,
            occupation,
            organisation,
            termsOfUseAccepted,
        });

        Alert.success("Your user has been created successfully.");
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        let message = "Please try again later.";

        if (e.response.status === 409) {
            message = "E-mail address in use";
        }

        Alert.error(`Failed to register: ${message}`);

        await logout();
    }
};

export const logout = async () => {
    try {
        await axios.get(api.auth.logout());
        return true;
    } catch (err) {
        console.log("Error on logout", err);
        Alert.error(
            "Logout failed, please clear your browser cookies to log out.",
        );
        return false;
    }
};

export const sendResetEmail = async email => {
    try {
        await axios.post(api.auth.requestReset(), { email });

        return true;
    } catch (e) {
        Alert.error(`Failed to send email: please try again later.`);
        return false;
    }
};

export const resetPassword = async ({ password, token }) => {
    try {
        await axios.post(api.auth.sendToken(), { token });
    } catch (e) {
        Alert.error(`Failed: Failed to verify user.`);

        return false;
    }

    try {
        await axios.put(api.auth.resetPassword(), { password });

        return true;
    } catch (e) {
        Alert.error(`Failed: Failed to reset password.`);

        return false;
    }
};

export const sendFeedback = async ({ email, text }) => {
    try {
        await axios.post(api.feedback(), { email, text });

        return true;
    } catch (e) {
        Alert.error("Failed to send your feedback. Please try again later.");
        return false;
    }
};

export const sendSupportQuestion = async ({ email, text }) => {
    try {
        await axios.post(api.support(), { email, text });

        return true;
    } catch (e) {
        Alert.error(
            "Failed to send your question. Please try again later or sent an email to support@edia.nl",
        );
        return false;
    }
};

export const sendEmailNotificationMobile = async ({ email }) => {
    try {
        await axios.post(api.notificationMobile(), { email });

        return true;
    } catch (e) {
        Alert.error("Failed to register your email. Please try again later.");
        return false;
    }
};

export const updateUser = async termsOfUseAccepted => {
    try {
        await axios.put(api.auth.updateUser(), { termsOfUseAccepted });
        return true;
    } catch (e) {
        Alert.error(
            "Failed to update your GDPR response. Please try again later.",
        );
        await logout();
    }
};
