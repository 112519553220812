import React from "react";
import PasswordBlock from "./PasswordBlock";

const PasswordResetInput = ({ sending, handleChange, handleSubmit }) => (
    <PasswordBlock>
        <form
            onSubmit={handleSubmit}
            className="PasswordEnterEmail__block__container"
        >
            <h3 className="PasswordEnterEmail__block__container__title">
                Create a new password
            </h3>
            <fieldset>
                <label>Password</label>
                <input
                    className="PasswordEnterEmail__block__container__input"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    required
                />
            </fieldset>
            <fieldset>
                <label>Confirm Password</label>
                <input
                    className="PasswordEnterEmail__block__container__input"
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    required
                />
            </fieldset>
            <button
                className="PasswordEnterEmail__block__container__action"
                type="submit"
                disabled={sending}
            >
                {sending ? "Sending" : "Send"}
            </button>
        </form>
    </PasswordBlock>
);

export default PasswordResetInput;
