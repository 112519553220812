import React from "react";
import { Link } from "react-router-dom";
import PasswordBlock from "../Password/PasswordBlock";

import Envelope from "../../images/papyrus_password_send_icon.svg";
import "../../styles/FeedbackForm.scss";

const FeedbackFormSend = () => (
    <PasswordBlock>
        <div className="FeedbackForm__container send">
            <h3 className="FeedbackForm__container__title">
                Your feedback has been send
            </h3>
            <img src={Envelope} alt="envelope" />
            <Link to="/" className="FeedbackForm__action">
                Continue
            </Link>
        </div>
    </PasswordBlock>
);

export default FeedbackFormSend;
