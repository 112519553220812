import React from "react";
import { markerState, isFilledArray } from "../../config/helpers";
import BlockSection from "../Block/BlockSection";
import MarkerPicker from "../MarkerPicker/MarkerPicker";
import { useTranslation } from "react-i18next";

const LinguisticsMarker = ({
    userId,
    language,
    workspace,
    setMarker,
    marker,
    isContentOutdated,
    wordStatistics = [],
}) => {
    const { t } = useTranslation();

    return (
        <BlockSection
            className="KeywordMarker"
            title={t("sidebar.areas_of_interest")}
            showHelp={true}
            explanation={t("editor.pos_tag_explanation")}
        >
            <MarkerPicker
                userId={userId}
                language={language}
                workspace={workspace}
                marker={marker}
                markers={[markerState.NONE, markerState.POSTAGS]}
                setMarker={setMarker}
                disabled={isContentOutdated || isFilledArray(wordStatistics)}
            />
        </BlockSection>
    );
};

export default LinguisticsMarker;
