import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendFeedback } from "../AuthorComponents";
import Close from "../../images/x-mark-white.svg";

import "../../styles/FeedbackForm.scss";
import FeedbackFormSend from "./FeedbackFormSend";

const FeedbackForm = () => {
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const [sending, isSending] = useState(false);
    const [success, isSuccess] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        isSending(true);
        const successful = await sendFeedback({ email, text });
        isSuccess(successful);
        isSending(false);
    };

    if (success) return <FeedbackFormSend />;

    return (
        <div className="FeedbackForm">
            <form className="FeedbackForm__container" onSubmit={handleSubmit}>
                <div className="FeedbackForm__container__title">
                    <h1>Feedback Form</h1>
                    <Link
                        to="/"
                        className="FeedbackForm__container__title-close"
                    >
                        <img src={Close} alt="close" />
                    </Link>
                </div>
                <p className="FeedbackForm__container__subtitle">
                    Are there things that you like about our tool or do you see
                    room for improvement? Let us know! We would love to hear
                    from you!
                </p>
                <fieldset className="FeedbackForm__container__block">
                    <label className="FeedbackForm__container__block__label">
                        Email Address
                    </label>
                    <input
                        name="email"
                        type="email"
                        onChange={e => setEmail(e.currentTarget.value)}
                        className="FeedbackForm__container__block__input"
                    />
                </fieldset>

                <fieldset className="FeedbackForm__container__block">
                    <label className="FeedbackForm__container__block__label">
                        Your Feedback
                    </label>
                    <textarea
                        name="text"
                        onChange={e => setText(e.currentTarget.value)}
                        className="FeedbackForm__container__block__input message"
                        required
                    />
                </fieldset>
                <button
                    className="FeedbackForm__action"
                    type="submit"
                    disabled={sending}
                >
                    {sending ? "Sending..." : "Send"}
                </button>
            </form>
        </div>
    );
};

export default FeedbackForm;
