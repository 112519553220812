import React from "react";
import TagManager from "react-gtm-module";
import AboveTargetLevelPopup from "../components/TextEditor/popups/AboveTargetLevelPopup";
import KeywordPopup from "../components/TextEditor/popups/KeywordPopup";
import PosTagsPopup from "../components/TextEditor/popups/PosTagsPopup";

export const languageOptions = [
    { name: "Dutch", option: "nl" },
    { name: "English", option: "en" },
    { name: "German", option: "de" },
];

export const halfLevelArray = [
    { name: "A1", number: 0, color: "#648FFF" },
    { name: "A2", number: 1, color: "#785EF0" },
    { name: "A2+", number: 2, color: "#785EF0" },
    { name: "B1", number: 3, color: "#FFB000" },
    { name: "B1+", number: 4, color: "#FFB000" },
    { name: "B2", number: 5, color: "#FE6100" },
    { name: "B2+", number: 6, color: "#FE6100" },
    { name: "C1", number: 7, color: "#DC267F" },
    { name: "C2", number: 8, color: "#DC267F" },
];
export const cefrScale = [
    { name: "A1", number: 0 },
    { name: "A2", number: 1 },
    { name: "A2+", number: 2 },
    { name: "B1", number: 3 },
    { name: "B1+", number: 4 },
    { name: "B2", number: 5 },
    { name: "B2+", number: 6 },
    { name: "C1", number: 7 },
    { name: "C2", number: 8 },
    { name: "unknown", number: 9 },
];

export const posTagColors = {
    ADJ: "#56B4E9",
    ADP: "#CC6677",
    ADV: "#E69F00",
    AUX: "#CC79A7",
    CONJ: "#D55E00",
    CCONJ: "#000",
    DET: "#AA4499",
    INTJ: "#332288",
    NOUN: "#0072B2",
    NUM: "#F0E442k",
    PART: "#882255",
    PRON: "#88CCEE",
    PROPN: "#0072B2",
    PUNCT: "white",
    SCONJ: "#DDCC77",
    SYM: "#44AA99",
    VERB: "#009E73",
    X: "white",
    SPACE: "white",
};

export const idMapping = {
    FKGL: "Flesch-Kincaid Grade Level",
    SMOG: "SMOG",
    CL: "Coleman-Liau",
    ARI: "ARI",
    FKRE: "Flesch-Kincaid reading ease",
    DC: "Dale-Chall",
    LW: "Linsear Write",
    FOG: "FOG",
};

export const CEFR_LEVELS = {
    LEVEL_6_CLASS: "6_CLASS",
    LEVEL_9_CLASS: "9_CLASS",
};

// This function calculates the color intensity for highlighting text/words
// The intensity (based on opacity) goes up on a linear scale starting from the
// taget level the user set, so for each CEFR level you go up from the target Level
// the opacity changes with the same percentage

export const colorOpacity = (receivedValue, targetLevel) => {
    return (receivedValue - targetLevel) / 10;
};

export const markerState = {
    NONE: "NONE",

    // cefr
    ABOVE_TARGET: "ABOVE_TARGET",

    CEFR_ALL_WORDS: "CEFR_ALL_WORDS",

    // topic
    KEYWORDS: "KEYWORDS",

    // Pos tags/ linguistics
    POSTAGS: "POSTAGS",
};

export const highlightsFilter = (tokenDifficulties, targetLevel) =>
    tokenDifficulties.filter(item => item.value > targetLevel);

export const getHighlights = (
    userId,
    language,
    workspace,
    overlay,
    tokenDifficulties,
    keywords,
    wordStatistics,
    classLevel,
    targetLevel,
    keywordToken,
) => {
    switch (overlay) {
        case markerState.KEYWORDS:
            return getKeywordHighlights(keywords, keywordToken, language);

        case markerState.ABOVE_TARGET:
            return getAboveTargetLevelHighlights(
                userId,
                language,
                workspace,
                highlightsFilter(tokenDifficulties, targetLevel),
                classLevel,
                targetLevel,
            );
        case markerState.CEFR_ALL_WORDS:
            return getCefrForAllWordsHighlights(
                userId,
                language,
                workspace,
                tokenDifficulties,
                classLevel,
                targetLevel,
            );
        case markerState.POSTAGS:
            return getPosTagHighlights(wordStatistics);
        case markerState.NONE:
        default:
            return [];
    }
};

export const getCefrForAllWordsHighlights = (
    userId,
    language,
    workspace,
    tokenDifficulties = [],
    classLevel,
    targetLevel,
) =>
    tokenDifficulties.map(tokenDifficulty => {
        const {
            indices: [start, end],
            value,
        } = tokenDifficulty;

        let roundedValue = value < 0 ? 0 : Math.round(value);

        const color =
            halfLevelArray[roundedValue] && halfLevelArray[roundedValue].color;

        return {
            start,
            length: end - start,
            style: {
                borderBottom: `2px solid ${color}`,
            },
            renderPopup: ({ replace, text, popper }) => (
                <AboveTargetLevelPopup
                    userId={userId}
                    language={language}
                    workspace={workspace}
                    tokenDifficulty={tokenDifficulty}
                    classLevel={classLevel}
                    targetLevel={targetLevel}
                    replace={replace}
                    text={text}
                    popper={popper}
                />
            ),
        };
    });

export const getPosTagHighlights = wordStatistics =>
    wordStatistics.map(word => {
        const {
            indices: [start, end],
            pos,
            lemma,
            syllables,
            corpusRank,
        } = word;

        return {
            start,
            length: end - start,
            style: {
                borderBottom: `2px solid ${posTagColors[pos]}`,
            },
            renderPopup: () => (
                <PosTagsPopup
                    pos={pos}
                    lemma={lemma}
                    syllables={syllables}
                    corpusRank={corpusRank}
                />
            ),
        };
    });

export const getKeywordHighlights = (keywords, keywordToken, language) =>
    keywords.map(keyword => {
        const {
            indices: [start, end],
            token,
        } = keyword;

        const color = token === keywordToken ? `#ff601d` : `#785EF0`;
        return {
            start,
            length: end - start,
            style: { borderBottom: `2px solid ${color}` },
            renderPopup: () => (
                <KeywordPopup keyword={keyword} language={language} />
            ),
        };
    });
//Create highlights with data from BE
export const getAboveTargetLevelHighlights = (
    userId,
    language,
    workspace,
    tokenDifficulties = [],
    classLevel,
    targetLevel,
) =>
    tokenDifficulties.map(tokenDifficulty => {
        const {
            indices: [start, end],
            value,
        } = tokenDifficulty;
        const opacity = colorOpacity(value, targetLevel);

        return {
            start,
            length: end - start,
            color: `rgba(255, 0, 0, ${opacity})`,
            renderPopup: ({ replace, text, popper }) => (
                <AboveTargetLevelPopup
                    userId={userId}
                    language={language}
                    workspace={workspace}
                    tokenDifficulty={tokenDifficulty}
                    replace={replace}
                    classLevel={classLevel}
                    targetLevel={targetLevel}
                    text={text}
                    popper={popper}
                />
            ),
        };
    });

export const getColorValue = (currentLevel, targetLevel) =>
    `rgba(255,0,0, ${colorOpacity(currentLevel, targetLevel)})`;

export const errorCodeMessages = {
    429: "Too many requests",
    413: "Your text is too long.",
};

export const initialState = {
    text: "",
    analyses: {
        cefr: { cefr: { name: null }, gse: null, numeric: null },
        topics: { topics: [] },
        keywords: { keywords: [] },
        textStatistics: {
            textStatistics: {},
            wordStatistics: [],
        },
        readability: { readabilityScores: [] },
    },
    targetLevel: 3,
};

export const createConstants = lastAnalysis => {
    const text = lastAnalysis ? lastAnalysis.text : initialState.text;
    const targetLevel = lastAnalysis
        ? lastAnalysis.targetLevel
        : initialState.targetLevel;
    const analyses = lastAnalysis
        ? lastAnalysis.analyses
        : initialState.analyses;
    const cefr = analyses.cefr
        ? analyses.cefr.cefr
        : initialState.analyses.cefr.cefr;
    const readability = analyses.readability
        ? analyses.readability.readabilityScores
        : initialState.analyses.readability.readabilityScores;
    const numeric = analyses.cefr
        ? analyses.cefr.numeric
        : initialState.analyses.cefr.numeric;
    const gse = analyses.cefr
        ? analyses.cefr.gse
        : initialState.analyses.cefr.gse;

    return { text, targetLevel, cefr, readability, numeric, gse };
};

export const isFilledArray = arr => Array.isArray(arr) && arr.length > 0;

//TODO: use constants
//Will expand as feature toggle expands

export const modules = {
    LOGIN: "author-login",
    CEFR_SCALE: "author-cefr-scale",
    GSE_SCALE: "author-gse-scale",
    WORD_DISAMBIGUATIONS: "author-word-disambiguations",
    DIFFICULT_WORDS: "author-difficult-words",
    KEYWORDS: "author-keywords",
    TOPICS: "author-topics",
    TEXT_STATISTICS: "author-text-statistics",
    READABILITY_SCORE: "author-readability-scales",
    READER_COMPREHENSION: "author-reader-comprehension",
    POS_TAGS: "author-pos-tags",
};

export const userHasPermission = (expected_permission, user_permissions) =>
    user_permissions.includes(expected_permission);

export const gtmpushToDataLayer = (eventName, eventParameters = {}) => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            ...eventParameters,
        },
        dataLayerName: "PageDataLayer",
    });
};
