import React from "react";
import PageHeader from "../Header/PageHeader";
import LandingPage from "./LandingPage";
import "../../styles/HomePage.scss";

import { Route } from "react-router-dom";
import CefrPaper from "../CefrPaper/CefrPaper";

class Homepage extends React.Component {
    render() {
        return (
            <div className="HomePage">
                <PageHeader showContactPopUp={this.showContactPopUp} />

                <div className="HomePage__container">
                    <Route path="/tech" component={CefrPaper} />
                    <Route exact path="/" component={LandingPage} />
                    {/*<Sidebar
                        showContactPopUp={this.showContactPopUp}
                        {...this.props}
                    />*/}
                </div>
            </div>
        );
    }
}

export default Homepage;
