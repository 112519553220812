/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BlockSection from "../Block/BlockSection";
import "../../style/components/KeywordBlock.css";
import { markerState } from "../../config/helpers";

import KeywordFilter from "./KeywordFilter";
import { useTranslation } from "react-i18next";

const filterArray = { FREQUENCY: "FREQUENCY", CERTAINTY: "CERTAINTY" };

const KeywordBlock = ({
    keywords,
    setKeywordToken,
    setMarker,
    keywordToken,
    isContentOutdated,
}) => {
    const [filter, setFilter] = useState(filterArray.FREQUENCY);
    const [uniqueKeywords, setUniqueKeywords] = useState([]);
    const [countedKeywords, setCountedKeywords] = useState([]);
    const [certaintyKeywords, setCertaintyKeywords] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        const resetKeywordArrays = () => {
            if (isContentOutdated) {
                setUniqueKeywords([]);
                setCountedKeywords([]);
                setCertaintyKeywords([]);
            }
        };

        const filterUniqueKeywords = () => {
            keywords &&
                keywords.forEach(keyword => {
                    let lemma = keyword.lemma;
                    if (!uniqueKeywords.includes(lemma)) {
                        uniqueKeywords.push(lemma);
                        certaintyKeywords.push({
                            keyword: lemma,
                            sortBy: keyword.certainty,
                            indices: keyword.indices,
                        });
                        countedKeywords.push({
                            keyword: lemma,
                            sortBy: keywords.filter(v => v.token === lemma)
                                .length,
                            indices: keyword.indices,
                        });
                    }
                });
        };
        resetKeywordArrays();
        filterUniqueKeywords();
    }, [isContentOutdated, keywords]);

    const keywordsWithFilter = keyword => {
        setKeywordToken(keyword);
        setMarker(markerState.KEYWORDS);
    };

    const renderKeywordList = keywordArray => {
        const leftColumn = [];
        const rightColumn = [];

        keywordArray.length &&
            keywordArray
                .sort((a, b) => b.sortBy - a.sortBy)
                .slice(0, 10)
                .map((keyword, i) => (
                    <div
                        key={keyword.keyword}
                        className={
                            keyword.keyword === keywordToken
                                ? "author-KeywordBlock__keyword active"
                                : "author-KeywordBlock__keyword"
                        }
                        onClick={() => keywordsWithFilter(keyword.keyword)}
                        value={keyword.keyword}
                    >
                        <span>
                            {`${i + 1}.`} {keyword.keyword}
                        </span>
                    </div>
                ))
                .forEach((el, i) =>
                    i < 5 ? leftColumn.push(el) : rightColumn.push(el),
                );

        return (
            <div className="author-KeywordBlock__keywords">
                <div className="author-KeywordBlock__keywords-column">
                    {leftColumn}
                </div>
                <div className="author-KeywordBlock__keywords-column">
                    {rightColumn}
                </div>
            </div>
        );
    };

    if (!keywords || !keywords.length) return null;
    return (
        <BlockSection
            className="author-KeywordBlock__section"
            title="Keyword Filters"
            showHelp={true}
            explanation={t("topic.explanation")}
        >
            <KeywordFilter filter={filter} setFilter={setFilter} />

            {filter === filterArray.FREQUENCY
                ? renderKeywordList(countedKeywords)
                : renderKeywordList(certaintyKeywords)}
        </BlockSection>
    );
};

export default KeywordBlock;
