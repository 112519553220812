import React from "react";
import LandingImage1 from "./LandingImage1";
import { useTranslation } from "react-i18next";

const Animation = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="Mainpage__info">
                <h1>
                    {t("landing.write_for")}{" "}
                    <strong>{t("landing.reading_level")}</strong>{" "}
                    {t("landing.of_your_audience")}
                </h1>
                <p>{t("landing.animation_text")}</p>
            </div>
            <LandingImage1 />
        </section>
    );
};

export default Animation;
