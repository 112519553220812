import React from "react";
// import PowerfulImage from "../../images/powerful.svg";
import powerfulMobile from "../../images/powerful_mobile.svg";
import { useTranslation } from "react-i18next";

const Powerful = () => {
    const { t } = useTranslation();

    return (
        <section className="powerful">
            <span className="Mainpage__info">
                <div className="Mainpage__info-special">
                    <h1>
                        <strong>{t("landing.confidently")}</strong>{" "}
                        {t("landing.publish_content")}
                    </h1>
                    <p>{t("landing.powerful_text")}</p>
                </div>
            </span>
            <div className="Mainpage__powerful">
                <img src={powerfulMobile} alt="powerful" />
            </div>
        </section>
    );
};

export default Powerful;
