import React from "react";
import Loader from "../../Block/Loader";
import { gtmpushToDataLayer, halfLevelArray } from "../../../config/helpers";
import { fetchPhraseReplacements } from "../../../config/Edia360ApiClient";
import "../../../style/components/AboveTargetLevelPopup.css";
import { withTranslation } from "react-i18next";

class AboveTargetLevelPopup extends React.Component {
    state = {
        replacements: [],
        loadingReplacements: false,
    };

    async componentDidMount() {
        const {
            text,
            classLevel,
            targetLevel,
            tokenDifficulty,
            popper,
        } = this.props;
        this.setState({ loadingReplacements: true });
        try {
            const { replacements } = await fetchPhraseReplacements({
                text,
                classLevel,
                targetLevel,
                startIndex: tokenDifficulty.indices[0],
                endIndex: tokenDifficulty.indices[1],
            });
            this.setState({ replacements, loadingReplacements: false }, () =>
                popper.update(),
            );
        } catch (e) {
            this.setState({ loadingReplacements: false, message: e.message });
        }
    }

    showCefrRange = (cefrMin, cefrMax) =>
        cefrMin === cefrMax ? cefrMin : `${cefrMin} - ${cefrMax}`;

    renderWordReplacementSuggestions = () => {
        const {
            userId,
            language,
            workspace,
            targetLevel,
            replace,
        } = this.props;
        const { replacements, loadingReplacements } = this.state;
        const leftColumn = [];
        const rightColumn = [];

        if (loadingReplacements) {
            return <Loader />;
        }

        [...replacements]
            .sort((a, b) => b.score - a.score)
            .map(({ phrase, label }) => {
                return (
                    <div
                        key={phrase}
                        onClick={() => {
                            replace(phrase);
                            gtmpushToDataLayer("word_suggestion_replacements", {
                                user: userId,
                                language,
                                workspaceID: workspace,
                                targetLevel,
                            });
                        }}
                    >
                        {phrase} {`(${label})`}
                    </div>
                );
            })
            .forEach((el, i) =>
                i % 2 === 0 ? leftColumn.push(el) : rightColumn.push(el),
            );

        if (leftColumn.length === 0 && rightColumn.length === 0) {
            return (
                <div className="author-AboveTargetLevelPopup__replacement-suggestions">
                    <span>{this.props.t("editor.no_alternative_words")}</span>
                </div>
            );
        }

        return (
            <div className="author-AboveTargetLevelPopup__replacement-suggestions">
                <div className="author-AboveTargetLevelPopup__replacement-suggestions-column">
                    {leftColumn}
                </div>
                <div className="author-AboveTargetLevelPopup__replacement-suggestions-column">
                    {rightColumn}
                </div>
            </div>
        );
    };

    render() {
        const { tokenDifficulty, t } = this.props;
        let roundedValueMin =
            tokenDifficulty.valueRange[0] < 0
                ? 0
                : Math.round(tokenDifficulty.valueRange[0]);
        let posTagNames = {
            ADJ: t("pos_tags.ADJ"),
            ADP: t("pos_tags.ADP"),
            PUNCT: t("pos_tags.PUNCT"),
            ADV: t("pos_tags.ADV"),
            AUX: t("pos_tags.AUX"),
            SYM: t("pos_tags.SYM"),
            INTJ: t("pos_tags.INTJ"),
            CCONJ: t("pos_tags.CCONJ"),
            X: t("pos_tags.X"),
            NOUN: t("pos_tags.NOUN"),
            DET: t("pos_tags.DET"),
            PROPN: t("pos_tags.PROPN"),
            NUM: t("pos_tags.NUM"),
            VERB: t("pos_tags.VERB"),
            PART: t("pos_tags.PART"),
            PRON: t("pos_tags.PRON"),
            SCONJ: t("pos_tags.SCONJ"),
            CONJ: t("pos_tags.CONJ"),
            SPACE: t("pos_tags.SPACE"),
        };

        const roundedValueMax = Math.round(tokenDifficulty.valueRange[1]);
        const cefrMin = halfLevelArray[roundedValueMin];
        const cefrMax = halfLevelArray[roundedValueMax];
        const posTag = posTagNames[tokenDifficulty.pos];

        return (
            <div className="author-AboveTargetLevelPopup">
                <h4>{t("editor.word_difficulty_range")}</h4>
                {this.showCefrRange(cefrMin.name, cefrMax.name)}
                <h4>{t("editor.part_of_speech")}</h4>
                {posTag || `(${t("editor.not_available")})`}
                <h4>{t("editor.suggested_alternatives")}</h4>
                {this.renderWordReplacementSuggestions()}
            </div>
        );
    }
}

export default withTranslation()(AboveTargetLevelPopup);
