import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import AuthorApp from "../AuthorApp/AuthorApp";
import Homepage from "../User/Homepage";
import { fetchUser } from "../AuthorComponents";
import PasswordEmail from "../Password/PasswordEmail";
import PasswordReset from "../Password/PasswordReset";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import GDPR from "../User/GDPR";
import ContactSupport from "../ContactSupport/ContactSupport";

import { withTranslation } from "react-i18next";

class App extends React.Component {
    state = {
        authorized: false,
        hasAccepted: false,
        user: undefined,
        isFetchingUser: true,
    };
    componentDidMount() {
        const lng = navigator.language;
        this.props.i18n.changeLanguage(lng);
        this.getUser();
    }

    clearUser = () => {
        this.setState({
            authorized: false,
            user: null,
        });
    };

    getUser = async () => {
        let user = await fetchUser();
        this.setState({
            authorized: Boolean(user && user.userId),
            hasAccepted: user.termsOfUseAccepted,
            isFetchingUser: false,
            user,
        });
    };

    render() {
        const { authorized, hasAccepted, isFetchingUser } = this.state;

        if (!authorized && isFetchingUser === false) {
            const apihost =
                window?.papyrus?.API_HOST || "https://papyrus.edia.nl";
            window.location.href = apihost + "/api/auth/login";
            return null;
            // return (
            //     <Switch>
            //         <Route
            //             path="/request-password-reset"
            //             exact
            //             component={PasswordEmail}
            //         />
            //         <Route path="/password/:token?" component={PasswordReset} />
            //         <Route path="/feedback" component={FeedbackForm} />
            //         <Route path="/support" component={ContactSupport} />
            //         <Route path="/">
            //             <Homepage
            //                 {...this.props}
            //                 {...this.state}
            //                 getUser={this.getUser}
            //             />
            //         </Route>
            //     </Switch>
            // );
        }

        if (authorized && !hasAccepted) {
            return (
                <Route
                    render={props => (
                        <GDPR
                            {...props}
                            getUser={this.getUser}
                            clearUser={this.clearUser}
                        />
                    )}
                />
            );
        }

        return (
            <Switch>
                <Route path="/feedback" component={FeedbackForm} />
                <Route
                    path="/"
                    render={props => (
                        <AuthorApp
                            {...this.props}
                            {...props}
                            clearUser={this.clearUser}
                        />
                    )}
                />
            </Switch>
        );
    }
}

export default withTranslation()(App);
