import React from "react";
import { useTranslation } from "react-i18next";

const TrainingData = () => {
    const { t } = useTranslation();

    return (
        <section className="CefrPaper__training">
            <h2>{t("landing.algorithm")}</h2>
            <div>
                <p>{t("landing.paragraph_three")}</p>
            </div>
        </section>
    );
};

export default TrainingData;
