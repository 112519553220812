import React from "react";
import { halfLevelArray } from "../../config/helpers";
import "../../style/components/CefrSettings.css";
import { useTranslation } from "react-i18next";

const CefrSettings = ({ selectTargetLevel, targetLevel }) => {
    const { t } = useTranslation();

    const handleTargetLevelChange = e => {
        e.preventDefault();
        selectTargetLevel(Number(e.currentTarget.value));
    };

    return (
        <fieldset className="author-CefrSettings">
            <label>
                <span className="author-CefrSettings__title">
                    {t("sidebar.select_your_target_level")}
                </span>
                <ul>
                    {halfLevelArray.map(({ name, number }) => (
                        <li
                            key={name}
                            onClick={handleTargetLevelChange}
                            value={number}
                            className={number === targetLevel ? "selected" : ""}
                        >
                            {name}
                        </li>
                    ))}
                </ul>
            </label>
        </fieldset>
    );
};

export default CefrSettings;
