import React from "react";
import "../../../style/components/KeywordPopup.css";
import { useTranslation } from "react-i18next";

const PosTagsPopup = ({ pos, lemma }) => {
    const { t } = useTranslation();

    const posTagNames = {
        ADJ: t("pos_tags.ADJ"),
        ADP: t("pos_tags.ADP"),
        PUNCT: t("pos_tags.PUNCT"),
        ADV: t("pos_tags.ADV"),
        AUX: t("pos_tags.AUX"),
        SYM: t("pos_tags.SYM"),
        INTJ: t("pos_tags.INTJ"),
        CCONJ: t("pos_tags.CCONJ"),
        X: t("pos_tags.X"),
        NOUN: t("pos_tags.NOUN"),
        DET: t("pos_tags.DET"),
        PROPN: t("pos_tags.PROPN"),
        NUM: t("pos_tags.NUM"),
        VERB: t("pos_tags.VERB"),
        PART: t("pos_tags.PART"),
        PRON: t("pos_tags.PRON"),
        SCONJ: t("pos_tags.SCONJ"),
        CONJ: t("pos_tags.CONJ"),
        SPACE: t("pos_tags.SPACE"),
    };

    return (
        <div className="author-KeywordPopup">
            <table>
                <tbody>
                    <tr>
                        <td>{t("editor.pos_tag")}:</td>
                        <td> {posTagNames[pos]}</td>
                    </tr>
                    <tr>
                        <td>{t("editor.lemma")}:</td>
                        <td>{lemma}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PosTagsPopup;
