import React from "react";

import "../../style/components/Loader.css";

const Loader = () => (
    <ul className="Loader">
        <li className="Loader__circle" />
        <li className="Loader__circle" />
        <li className="Loader__circle" />
    </ul>
);

export default Loader;
