import React from "react";
import { Link } from "react-router-dom";
import PasswordBlock from "./PasswordBlock";

import Party from "../../images/papyrus_password_changed_icon.svg";

const PasswordResetSend = () => (
    <PasswordBlock>
        <div className="PasswordEnterEmail__block__container">
            <h3 className="PasswordEnterEmail__block__container__title">
                Password changed successfully
            </h3>
            <img
                src={Party}
                alt="party"
                style={{ height: "175px", margin: "32px" }}
            />
            <Link
                to="/"
                className="PasswordEnterEmail__block__container__action"
            >
                Go to Login
            </Link>
        </div>
    </PasswordBlock>
);

export default PasswordResetSend;
