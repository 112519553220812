import React from "react";

import chartLeft from "../../images/heat_map.svg";
import chartRight from "../../images/all_data_heat_map.svg";
import { useTranslation } from "react-i18next";
// import Charts from "../../images/two_charts.png";

const HumanAnnotators = () => {
    const { t } = useTranslation();

    return (
        <section className="CefrPaper__human">
            <h2>{t("landing.human_annotators")}</h2>
            <div>
                <div className="CefrPaper__human__container">
                    <div className="CefrPaper__human__container__image">
                        <img src={chartLeft} alt="human annotator charts" />
                    </div>
                    <span>{t("landing.span_one")}</span>
                </div>
                <div className="CefrPaper__human__container">
                    <div className="CefrPaper__human__container__image">
                        <img src={chartRight} alt="human annotator charts" />
                    </div>
                    <span>{t("landing.span_two")}</span>
                </div>
            </div>
        </section>
    );
};

export default HumanAnnotators;
