import React from "react";

import "../../style/components/ReadabilityContainer.css";
import Block from "../Block/Block";
import ReadabilityScores from "./ReadabilityScores";
import { idMapping } from "../../config/helpers";
import { withTranslation } from "react-i18next";

class ReadabilityContainer extends React.Component {
    getFleschKincaid = (readability = []) => {
        const score =
            readability.find(item => item.name === idMapping.FKRE) || {};
        return score.level;
    };

    render() {
        const { readability, isLoading, t } = this.props;

        return (
            <Block
                className="author-Readability"
                title={t("readability.title")}
                result={this.getFleschKincaid(readability)}
                close={true}
                isLoading={isLoading}
            >
                <ReadabilityScores readability={readability} />
            </Block>
        );
    }
}

export default withTranslation()(ReadabilityContainer);
