import React from "react";

import { useTranslation } from "react-i18next";

const PaperHeader = () => {
    const { t } = useTranslation();

    const paragraphOne = {
        __html: t("landing.paragraph_one"),
    };

    return (
        <section className="CefrPaper__header">
            <h1>{t("landing.page_header")}</h1>
            <p dangerouslySetInnerHTML={paragraphOne} />
        </section>
    );
};

export default PaperHeader;
