import React from "react";
import Alert from "react-s-alert";
import PasswordResetInput from "./PasswordResetInput";
import PasswordResetSend from "./PasswordResetSend";
import { resetPassword } from "../AuthorComponents";

import "../../styles/PasswordEnterEmail.scss";

class PasswordReset extends React.Component {
    state = {
        password: "",
        confirmPassword: "",
        sending: false,
        passwordSend: false,
    };

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { password, confirmPassword } = this.state;
        const { match } = this.props;

        const token = match.params.token || "";

        if (password !== confirmPassword) {
            return Alert.error("Password and confirm password do not match.");
        }
        this.setState({ sending: true });
        const passwordSend = await resetPassword({ password, token });
        this.setState({ passwordSend, sending: false });
    };

    render() {
        return !this.state.passwordSend ? (
            <PasswordResetInput
                sending={this.state.sending}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
            />
        ) : (
            <PasswordResetSend />
        );
    }
}

export default PasswordReset;
