import Delta from "quill-delta";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getHighlights, markerState } from "../../config/helpers";
import "../../style/components/TextEditor.css";
import { closeHighlightPopper, formats } from "./blots/Highlight";
import { withTranslation } from "react-i18next";

class TextEditor extends React.Component {
    state = {
        content: this.props.text,
    };

    componentDidUpdate(_prevProps) {
        if (this.props.overlay !== markerState.NONE) {
            this.showHighlighting(this.previewTextEditor);
        }

        closeHighlightPopper();
    }

    onChange = text => {
        if (!this.props.reactQuill.current) {
            return;
        }

        this.props.handleChange(text);

        this.setState(() => ({
            content: this.props.reactQuill.current.editor.getContents(),
        }));
    };

    preventHighlightCopy = e =>
        (e.metaKey || e.ctrlKey) && this.props.setMarker(markerState.NONE);

    previewTextEditorRef = el => (this.previewTextEditor = el);

    showHighlighting = previewTextEditor => {
        const {
            userId,
            language,
            workspace,
            overlay,
            tokenDifficulties,
            keywords,
            wordStatistics,
            classLevel,
            targetLevel,
            wordSenses,
            keywordToken,
        } = this.props;

        const highlights = getHighlights(
            userId,
            language,
            workspace,
            overlay,
            tokenDifficulties,
            keywords,
            wordStatistics,
            classLevel,
            targetLevel,
            wordSenses,
            keywordToken,
        );

        const { editor } = this.props.reactQuill.current;
        const text = editor.getText();
        const replaceText = highlight => text => {
            const format = editor.getFormat(highlight.start, highlight.length);
            editor.deleteText(highlight.start, highlight.length);
            editor.insertText(highlight.start, text, format);
        };

        const delta = new Delta();

        [...highlights]
            // sort highlights so retain ranges make sense
            .sort((a, b) => a.start - b.start)
            .reduce((prev, curr, i) => {
                const transparentBackground =
                    i % 2 ? "transparent" : "rgba(255, 255, 255, 0)";

                if (prev && curr.start < prev.start + prev.length) {
                    // Calculate the length difference for overlapping indices
                    // And set length of last overlapping token to new difference
                    const lengthDifference =
                        curr.start - (prev.start + prev.length);

                    curr.start = prev.start + prev.length;
                    curr.length = curr.length + lengthDifference;
                }

                if (prev) {
                    // Retain formatting between the end of the last highlight
                    // and the start of the new one
                    delta.retain(curr.start - (prev.start + prev.length));
                } else {
                    // Retain formatting before (first) highlight
                    delta.retain(curr.start);
                }

                delta.retain(curr.length, {
                    div: {
                        ...curr,
                        replaceText: replaceText(curr),
                        text,
                        style: {
                            // quill optimizes the HTML and merges adjacent element it considers to have the same style,
                            // it seems to only compare values its default formats support (e.g. backgroundColor)
                            // highlights that underline text using a border and share the same bg color are merged as they are considered equal
                            // this causes issue when you want to highlight two adjacent character ranges
                            // to work around this, alternate between two different implementations of a transparent background,
                            // so that quill recognizes they are styled differently.
                            backgroundColor: transparentBackground,
                            ...(curr.style || {}),
                        },
                    },
                });

                return curr;
            }, null);

        previewTextEditor.editor.updateContents(delta);
    };

    handlePreviewEditorFocus = range => {
        const { editor } = this.props.reactQuill.current;
        // TODO use ref instead
        const $wrapper = document.querySelector(`.author-TextEditor__wrapper`);
        const scrollTop = $wrapper.scrollTop;

        editor.setSelection(range);

        $wrapper.scrollTop = scrollTop;
    };

    render() {
        const {
            userFound,
            text,
            limits,
            reactQuill,
            overlay = markerState.NONE,
            t,
        } = this.props;

        return (
            <div
                className={`author-TextEditor ${
                    overlay !== markerState.NONE
                        ? "author-TextEditor--previewing"
                        : ""
                }`}
            >
                <div className="author-TextEditor__wrapper">
                    <div className="author-TextEditor__regular-text-editor">
                        {userFound && (
                            <ReactQuill
                                value={text}
                                ref={reactQuill}
                                onChange={this.onChange}
                                onKeyDown={this.preventHighlightCopy}
                                placeholder={t("editor.placeholder_text", {
                                    character_limit:
                                        limits &&
                                        limits["author-character-limit"]
                                            ? limits["author-character-limit"]
                                            : "1000",
                                })}
                                preserveWhitespace={true}
                                formats={formats}
                            />
                        )}
                    </div>

                    <div className="author-TextEditor__preview-text-editor">
                        <ReactQuill
                            value={this.state.content}
                            ref={this.previewTextEditorRef}
                            formats={formats}
                            preserveWhitespace={true}
                            onFocus={this.handlePreviewEditorFocus}
                        />
                        <div className="author-TextEditor__poppers" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TextEditor);
