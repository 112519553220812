import React from "react";
import "../../styles/Testimonials.scss";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
    const { t } = useTranslation();

    return (
        <section className="Testimonials">
            <div>
                <h1>
                    {t("landing.what")}{" "}
                    <strong>{t("landing.other_people")}</strong>{" "}
                    {t("landing.say")}
                </h1>
            </div>
            <div className="Testimonials__container">
                <div className="Testimonials__block">
                    <p className="Testimonials__block__quote">
                        "{t("landing.testimonial_one")}"
                    </p>
                    <span className="Testimonials__block__name">
                        Lisa Horvath,{" "}
                        <span className="Testimonials__block__name blue">
                            {t("landing.elt_developer")}
                        </span>
                    </span>
                </div>
                <div className="Testimonials__block">
                    <p className="Testimonials__block__quote">
                        "{t("landing.testimonial_two")}"
                    </p>

                    <p className="Testimonials__block__quote">
                        "{t("landing.testimonial_three")}"
                    </p>
                    <span className="Testimonials__block__name">
                        Andelie Zeeman,{" "}
                        <span className="Testimonials__block__name blue">
                            {t("landing.education_specialist")}
                        </span>
                    </span>
                </div>
                <div className="Testimonials__block">
                    <p className="Testimonials__block__quote">
                        "{t("landing.testimonial_four")}"
                    </p>
                    <span className="Testimonials__block__name">
                        ELT Author,{" "}
                        <span className="Testimonials__block__name blue">
                            {t("landing.editor_and_consultant")}
                        </span>
                    </span>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
