import React from "react";
import ediaLogo from "../../images/EDIAlogo.png";
import { useTranslation } from "react-i18next";

const PaperFooter = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div>
                <span className="edia-tag-line">
                    {t("landing.page_footer")}
                </span>
                <img src={ediaLogo} alt="edia" />
            </div>
            <div className="copyright">
                <p>
                    &copy; {t("landing.copyright")} {new Date().getFullYear()}
                </p>
            </div>
        </footer>
    );
};

export default PaperFooter;
