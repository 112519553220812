export default {
    cefr: (classLevel, targetLevel) =>
        `/api/cefr?scale=${classLevel}&targetLevel=${targetLevel}`,
    topics: () => `/api/topics`,
    textStatistics: () => `/api/text-statistics`,
    keywords: () => `/api/keywords`,
    readability: () => `/api/readability`,
    wordDifficulty: classLevel => `/api/word_difficulty?scale=${classLevel}`,

    auth: {
        login: () => `/api/auth/login`,
        current: () => `/api/user/current`,
        logout: () => `/api/auth/logout`,
        // register: () => `/api/user/register`,
        // requestReset: () => `/api/user/password/reset`,
        // sendToken: () => `/api/user/token-login`,
        // resetPassword: () => `/api/user/password/update`,
        // updateUser: () => `/api/user/update`,
    },
    lastText: () => `/api/text/last`,
    feedback: () => `/api/feedback`,
    notificationMobile: () => `/api/notification/mobile_release`,
    support: () => `/api/support`,
    replacePhrase: () => `/api/replace-phrase`,
};
