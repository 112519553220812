import React from "react";
import CefrProbabilities from "./CefrProbabilities";
import ReaderComprehensionGraph from "./ReaderComprehensionGraph";
import "../../style/components/AnalysisResultContainer.css";
import Block from "../Block/Block";
import DifficultyMarker from "./DifficultyMarker";
import { useTranslation } from "react-i18next";

const AnalysisResultContainer = ({
    cefr,
    probabilities,
    numeric,
    classLevel,
    targetLevel,
    isLoading,
    gse,
    language,
    hasCefrScalePermission,
    hasDifficultWordsPermission,
    hasGseScalePermission,
    hasReaderComprehension,
    ...rest
}) => {
    const { t } = useTranslation();

    const calculateGseScore = () => {
        if (gse) {
            if (gse === 90 || gse === 89) return `${gse - 2} - ${gse}`;
            if (gse === 10 || gse === 11) return `${gse} - ${gse + 2}`;

            return `${gse - 2} - ${gse + 2}`;
        }

        return "";
    };

    const getCefrReading = () => {
        return `${cefr.name} ${language ? language : ""}`;
    };

    const calculateCefrGse = () => {
        if (cefr.name && gse && language === "en")
            return `${getCefrReading()} / ${calculateGseScore()}`;
        else if (cefr.name) {
            return getCefrReading();
        }

        return "";
    };

    const getDashboardDisplay = () => {
        let result = "";
        if (hasGseScalePermission) result = calculateCefrGse();
        else if (hasCefrScalePermission) {
            result = getCefrReading();
        }

        return result;
    };

    return (
        <Block
            className="author-Readability"
            title={
                hasGseScalePermission
                    ? gse && language === "en"
                        ? t("cefr.cefr_or_gse_title")
                        : t("cefr.cefr_title")
                    : hasCefrScalePermission
                    ? t("cefr.cefr_title")
                    : t("cefr.readability_title")
            }
            result={getDashboardDisplay()}
            isLoading={isLoading}
        >
            {hasDifficultWordsPermission ? (
                <DifficultyMarker language={language} {...rest} />
            ) : null}
            {hasCefrScalePermission ? (
                <CefrProbabilities
                    {...rest}
                    cefrLevel={cefr.name}
                    classLevel={classLevel}
                    targetLevel={targetLevel}
                    cefrNumeric={numeric}
                />
            ) : null}
            {hasReaderComprehension ? (
                <ReaderComprehensionGraph
                    cefrNumeric={numeric}
                    classLevel={classLevel}
                    targetLevel={targetLevel}
                    cefrProbabilities={probabilities}
                />
            ) : null}
        </Block>
    );
};

export default AnalysisResultContainer;
