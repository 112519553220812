import React from "react";
import { Link } from "react-router-dom";
import PasswordBlock from "./PasswordBlock";

const PasswordEnterEmail = ({ sending, handleChange, handleSubmit }) => (
    <PasswordBlock>
        <form
            onSubmit={handleSubmit}
            className="PasswordEnterEmail__block__container"
        >
            <h3 className="PasswordEnterEmail__block__container__title">
                Request a new password
            </h3>
            <fieldset>
                <label>Email Address</label>
                <input
                    className="PasswordEnterEmail__block__container__input"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    required
                />
            </fieldset>

            <button
                className="PasswordEnterEmail__block__container__action"
                type="submit"
                disabled={sending}
            >
                {sending ? "Sending..." : "Send"}
            </button>
            <Link to="/" className="PasswordEnterEmail__block__container__exit">
                Cancel
            </Link>
        </form>
    </PasswordBlock>
);

export default PasswordEnterEmail;
