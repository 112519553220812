import React from "react";
import ClockImage from "../../images/clock.svg";
import { useTranslation } from "react-i18next";

const Clock = () => {
    const { t } = useTranslation();

    return (
        <section className="reverse">
            <img src={ClockImage} alt="clock" className="Mainpage__clock" />
            <div className="Mainpage__info">
                <h1>
                    {t("landing.write_high")}{" "}
                    <strong>{t("landing.quality")}</strong>{" "}
                    {t("landing.articles_in")}{" "}
                    <strong>{t("landing.less_time")}</strong>
                </h1>
                <p>{t("landing.clock_text")}</p>
            </div>
        </section>
    );
};

export default Clock;
