import React, { useState } from "react";
import { updateUser } from "../AuthorComponents";
import "../../styles/gdpr.scss";
import { useTranslation } from "react-i18next";

const GDPR = ({ getUser, clearUser }) => {
    const [termsOfUseAccepted, acceptAgreement] = useState(false);
    const [sending, isSending] = useState(false);

    const { t } = useTranslation();

    const handleChange = e => {
        acceptAgreement(e.target.checked);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        isSending(true);
        await updateUser(termsOfUseAccepted);
        getUser();
        isSending(false);
    };

    const handleLogout = async () => {
        clearUser();
        window.location.replace(window.papyrus.API_HOST + "/api/auth/logout");
    };

    return (
        <div className="GDPR">
            <form onSubmit={handleSubmit} className="GDPR__form">
                <h2 className="GDPR__form__title">
                    {t("landing.terms_of_service")}
                </h2>
                <p className="GDPR__form__text">
                    {t("landing.not_agreed")}{" "}
                    <a
                        href="https://www.edia.nl/terms-papyrus"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {t("landing.terms_of_use")}
                    </a>{" "}
                    {t("landing.and")}{" "}
                    <a
                        href="https://www.edia.nl/privacy"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {t("landing.privacy_policy")}
                    </a>
                    . {t("landing.accept_to_login")}.
                </p>
                <div className="GDPR__form__input">
                    <label>
                        <input
                            type="checkbox"
                            name="termsOfUseAccepted"
                            onChange={handleChange}
                        />
                        <span>
                            {t("landing.i_agree")}{" "}
                            <a
                                href="https://www.edia.nl/privacy"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("landing.privacy_policy_lower")}
                            </a>{" "}
                            {t("landing.and")}{" "}
                            <a
                                href="https://www.edia.nl/terms-papyrus"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t("landing.terms_of_use").toLowerCase()}
                            </a>
                        </span>
                    </label>
                </div>
                <button
                    type="submit"
                    className="GDPR__form__action"
                    disabled={sending}
                >
                    {sending
                        ? t("landing.updating_text")
                        : t("landing.confirm_agreement")}
                </button>
            </form>
            <button className="GDPR__form__exit" onClick={handleLogout}>
                {t("navbar.exit")}
            </button>
        </div>
    );
};

export default GDPR;
