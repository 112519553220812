import React from "react";
import "../../styles/LandingPage.scss";
import Footer from "./Footer";
import Animation from "./Animation";
import Clock from "./Clock";
import Powerful from "./Powerful";
import { Link } from "react-router-dom";
import Mobile from "./Mobile";
import animationImage from "../../images/animation_image.svg";
import Testimonials from "./Testimonials";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
    const { t } = useTranslation();

    return (
        <div className="Mainpage">
            <div className="Mainpage__container">
                <Animation />
                <img
                    src={animationImage}
                    alt="animation"
                    className="Mainpage__animationImage"
                />
                <Clock />
                <Powerful />
                <Testimonials />
                <div className="Mainpage__tagline">
                    {t("landing.create_account")}
                </div>
                <Mobile />

                <section className="Mainpage__technical">
                    <div>
                        <Link to="/tech">{t("landing.accuracy")} </Link>
                    </div>
                    <div>{t("landing.accuracy_description")}</div>
                </section>

                <Footer />
            </div>
        </div>
    );
};

export default LandingPage;
