import React from "react";
import { useTranslation } from "react-i18next";

const Footnotes = () => {
    const { t } = useTranslation();

    const notes = [
        {
            __html: `<a href='https://en.wikipedia.org/wiki/Machine_learning' target='_blank' rel='noreferrer noopener'>${t(
                "landing.machine_learning",
            )} (ML)</a> ${t(
                "landing.notes_one",
            )} <a href='https://en.wikipedia.org/wiki/Artificial_intelligence' target='_blank' rel='noreferrer noopener'>${t(
                "landing.artificial_intelligence",
            )}</a>.`,
        },
        {
            __html: t("landing.notes_two"),
        },
        {
            __html: t("landing.notes_three"),
        },
        {
            __html: `${t(
                "landing.the",
            )} <a href='https://merlin-platform.eu/C_mcorpus.php' target='_blank' rel='noreferrer noopener'>MERLIN dataset</a> ${t(
                "landing.notes_four",
            )}`,
        },
    ];

    const MultiLingualTech = {
        title: t("landing.multilingual_title"),
        text: {
            __html: t("landing.multilingual_text"),
        },
    };

    return (
        <section className="CefrPaper__footnotes">
            <div>
                <span>{MultiLingualTech.title}</span>
                <p dangerouslySetInnerHTML={MultiLingualTech.text} />
            </div>
            <div>
                {notes.map((item, i) => (
                    <span key={item + i}>
                        <sup>{i + 1}</sup> <p dangerouslySetInnerHTML={item} />
                    </span>
                ))}
            </div>
        </section>
    );
};

export default Footnotes;
