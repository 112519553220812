import React from "react";
import Edia360AuthorComponents, {
    TextEditor,
    CEFR_LEVELS,
    markerState,
    fetchUser,
} from "../AuthorComponents";

import "../../styles/AuthorApp.scss";
import PageHeader from "../Header/PageHeader";

class AuthorApp extends React.Component {
    state = {
        text: "",
        tokenDifficulties: [],
        keywords: [],
        keywordToken: "",
        wordStatistics: [],
        overlay: markerState.NONE,
        targetLevel: 3,
        //classLevel is static at the moment
        classLevel: CEFR_LEVELS.LEVEL_9_CLASS,
        contentOutdated: false,
        endSession: false,
        permissions: [],
        limits: null,
        userId: null,
        userFound: false,
        workspace: "",
        highlightEdit: false,
        language: "",
    };

    //Ref functions to create an anchor for Quill components
    reactQuill = React.createRef();

    async componentDidMount() {
        const user = await fetchUser();
        this.setState({
            userId: user && user.userId,
            userFound: true,
            permissions: user && user.permissions,
            limits: user && user.limits,
            workspace: user && user.context,
        });
    }

    // Save changes in text in state and disable highlights when editing
    handleChange = (text, highlightEdit) => {
        if (highlightEdit) {
            return this.setState({
                text,
                contentOutdated: true,
                keywords: [],
                wordStatistics: [],
                highlightEdit: true,
            });
        }

        this.setState({
            text,
            overlay: markerState.NONE,
            contentOutdated: this.state.text !== this.getEditor().getText(),
            tokenDifficulties: [],
            keywords: [],
            wordStatistics: [],
            highlightEdit: false,
        });
    };

    setOverlay = overlay => this.setState({ overlay });

    retrieveTargetLevel = targetLevel => this.setState({ targetLevel });

    //Retrieve data from Edia360AuthorComponents and save in state
    setTokenDifficulties = (tokenDifficulties = []) =>
        this.setState({ tokenDifficulties, highlightEdit: false });

    setKeywords = (keywords = []) =>
        this.setState({ keywords, contentOutdated: false });

    setKeywordToken = keywordToken => {
        this.setState({ keywordToken, contentOutdated: false });
    };

    clearKeywordToken = () =>
        this.setState({ keywordToken: "", contentOutdated: false });

    setWordStatistics = (wordStatistics = []) => {
        this.setState({ wordStatistics, contentOutdated: false });
    };

    getLastAnalysedText = (text, targetLevel) => {
        this.setState({ text, targetLevel, contentOutdated: false });
        this.getText();
    };

    //Create Ref for editor and get text
    getEditor = () =>
        this.reactQuill &&
        this.reactQuill.current &&
        this.reactQuill.current.getEditor();

    getText = () => {
        const editor = this.getEditor();
        return editor ? editor.getText() : this.state.text;
    };

    getTextLength = () => {
        const editor = this.getEditor();
        return editor ? editor.getLength() : 0;
    };

    showLogout = () =>
        this.setState(state => ({ endSession: !state.endSession }));

    handleLogout = async () => {
        const { clearUser } = this.props;
        clearUser();
        window.location.replace(window.papyrus.API_HOST + "/api/auth/logout");
    };

    render() {
        return (
            <div className="App">
                <PageHeader showMenu={true} logout={this.showLogout} />
                {this.state.userFound && (
                    <div className="App__container">
                        <TextEditor
                            tokenDifficulties={this.state.tokenDifficulties}
                            keywords={this.state.keywords}
                            keywordToken={this.state.keywordToken}
                            wordStatistics={this.state.wordStatistics}
                            overlay={this.state.overlay}
                            classLevel={this.state.classLevel}
                            targetLevel={this.state.targetLevel}
                            text={this.state.text}
                            reactQuill={this.reactQuill}
                            handleChange={this.handleChange}
                            setMarker={this.setOverlay}
                            userId={this.state.userId}
                            language={this.state.language}
                            workspace={this.state.workspace}
                            userFound={this.state.userFound}
                            permissions={this.state.permissions}
                            limits={this.state.limits}
                            wordSenses={this.state.synsets}
                        />
                        <Edia360AuthorComponents
                            getText={this.getText}
                            getTextLength={this.getTextLength}
                            marker={this.state.overlay}
                            setTokenDifficulties={this.setTokenDifficulties}
                            setKeywords={this.setKeywords}
                            setKeywordToken={this.setKeywordToken}
                            clearKeywordToken={this.clearKeywordToken}
                            setWordStatistics={this.setWordStatistics}
                            setMarker={this.setOverlay}
                            setWordSenses={this.setWordSenses}
                            retrieveTargetLevel={this.retrieveTargetLevel}
                            isContentOutdated={this.state.contentOutdated}
                            highlightEdit={this.state.highlightEdit}
                            getLastAnalysedText={this.getLastAnalysedText}
                            setPreferredLanguage={language =>
                                this.setState({ language })
                            }
                            onSessionTimeout={this.handleLogout}
                            endSession={this.state.endSession}
                            userId={this.state.userId}
                            workspace={this.state.workspace}
                            userFound={this.state.userFound}
                            permissions={this.state.permissions}
                            limits={this.state.limits}
                            keywordToken={this.state.keywordToken}
                            keywords={this.state.keywords}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default AuthorApp;
