import React from "react";
import "../../styles/PasswordEnterEmail.scss";

const PasswordBlock = ({ children }) => (
    <div className="PasswordEnterEmail">
        <div className="PasswordEnterEmail__block">{children}</div>
    </div>
);

export default PasswordBlock;
