import React from "react";
import { isFilledArray, markerState } from "../../config/helpers";
import "../../style/components/DifficultyMarker.css";
import BlockSection from "../Block/BlockSection";
import MarkerPicker from "../MarkerPicker/MarkerPicker";
import { useTranslation } from "react-i18next";

const DifficultyMarker = props => {
    const { t } = useTranslation();

    const actions = {
        [markerState.NONE]: props.clearHighlight,
        [markerState.ABOVE_TARGET]: props.getGseWordLevel,
        [markerState.CEFR_ALL_WORDS]: props.getCefrLevelForAllWords,
    };

    const setMarker = marker => actions[marker] && actions[marker]();

    const markerArray = [
        markerState.NONE,
        markerState.ABOVE_TARGET,
        markerState.CEFR_ALL_WORDS,
    ];

    return (
        <BlockSection
            className="DifficultyMarker"
            title={t("sidebar.areas_of_interest")}
            showHelp={true}
            explanation={t("cefr.word_difficulty_explanation")}
        >
            <MarkerPicker
                userId={props.userId}
                language={props.language}
                workspace={props.workspace}
                setMarker={setMarker}
                marker={props.marker}
                markers={markerArray}
                disabled={
                    (!isFilledArray(props.tokenDifficulties) &&
                        props.isContentOutdated) ||
                    !isFilledArray(props.tokenDifficulties)
                }
            />
        </BlockSection>
    );
};

export default DifficultyMarker;
