import React from "react";
import "../../style/components/Header.css";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="author-Header">
            <header>{t("sidebar.readability_analyser")}</header>
        </div>
    );
};

export default Header;
