import React from "react";
import "../../style/components/BlockSection.css";

class BlockSection extends React.Component {
    state = {
        showExplanation: false,
    };

    showToolExplanation = () =>
        this.setState(state => ({ showExplanation: !state.showExplanation }));

    render() {
        const {
            className = "",
            title,
            children,
            showHelp,
            explanation,
        } = this.props;
        return (
            <div className={`author-BlockSection ${className}`}>
                <div className="author-BlockSection__header">
                    <h2 className="author-BlockSection__title">{title}</h2>
                    {showHelp && (
                        <React.Fragment>
                            <span
                                className="pe-7s-help1"
                                style={{
                                    fontSize: "24px",
                                    color: "#FF601D",
                                    marginRight: 4,
                                }}
                                onClick={this.showToolExplanation}
                            />
                            <ToolExplanation
                                showExplanation={this.state.showExplanation}
                                explanation={explanation}
                                showToolExplanation={this.showToolExplanation}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div className="author-BlockSection__content">{children}</div>
            </div>
        );
    }
}

export default BlockSection;

export const ToolExplanation = ({
    showExplanation,
    explanation,
    showToolExplanation,
}) =>
    showExplanation ? (
        <div className="author-ToolExplanation" onClick={showToolExplanation}>
            {explanation}
        </div>
    ) : null;
