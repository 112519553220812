import React from "react";

import "../../style/components/ReadabilityScores.css";
import BlockSection from "../Block/BlockSection";
import { idMapping } from "../../config/helpers";
import { useTranslation } from "react-i18next";

export const barWidth = (score, maxScore) => {
    if (maxScore === 0) {
        const width = (score / 100) * 100;
        return 100 - width;
    }
    const width = (score / maxScore) * 100;
    return width;
};

const ReadabilityScores = ({ readability = [] }) => {
    const { t } = useTranslation();

    if (!readability.length) return null;
    return (
        <BlockSection
            className="author-ReadabilityScore"
            title={t("readability.readability_score")}
            showHelp={true}
            explanation={t("readability.readability_explanation")}
        >
            <div className="author-ReadabilityScore__list">
                {readability.map(item => (
                    <div className="score-item" key={item.name}>
                        <h3>{idMapping[item.id]}</h3>

                        <div className="score-container">
                            <span>{item.scaleStart}</span>
                            <div className="bar-container">
                                <ScoreBubble
                                    readabilityScore={item.readabilityScore}
                                    scaleEnd={item.scaleEnd}
                                />

                                <ScoreBar
                                    readabilityScore={item.readabilityScore}
                                    scaleEnd={item.scaleEnd}
                                />
                            </div>
                            <span>{item.scaleEnd}</span>
                        </div>

                        <span className="score-level">
                            {item.readabilityScore} ={" "}
                            {item.level === null ? "First Grade" : item.level}
                        </span>
                    </div>
                ))}
            </div>
        </BlockSection>
    );
};

export default ReadabilityScores;

export const ScoreBubble = ({ readabilityScore, scaleEnd }) => (
    <span
        className="score-number"
        style={{
            marginLeft: `calc(${barWidth(readabilityScore, scaleEnd) +
                "%"} - 10px)`,
        }}
    >
        {readabilityScore}
    </span>
);

export const ScoreBar = ({ readabilityScore, scaleEnd }) => (
    <div className="score-bar">
        <div
            className="score-bar-fill"
            style={{
                width: `${barWidth(readabilityScore, scaleEnd)}%`,
            }}
        />
    </div>
);
