import * as PropTypes from "prop-types";
import React from "react";
import { markerState, gtmpushToDataLayer } from "../../config/helpers";
import "../../style/components/MarkerPicker.css";
import { useTranslation } from "react-i18next";

const MarkerPicker = ({
    userId,
    language,
    workspace,
    setMarker,
    marker,
    markers,
    disabled,
}) => {
    const { t } = useTranslation();

    const markerTitles = {
        [markerState.NONE]: t("marker_titles.none"),
        [markerState.KEYWORDS]: t("marker_titles.keywords"),
        [markerState.ABOVE_TARGET]: t("marker_titles.difficult_words"),
        [markerState.POSTAGS]: t("marker_titles.pos_tags"),
        [markerState.CEFR_ALL_WORDS]: t("marker_titles.all_words"),
    };

    const markerDescriptions = {
        [markerState.NONE]: t("marker_descriptions.none"),
        [markerState.KEYWORDS]: t("marker_descriptions.keywords"),
        [markerState.ABOVE_TARGET]: t("marker_descriptions.difficult_words"),
        [markerState.POSTAGS]: t("marker_descriptions.pos_tags"),
        [markerState.CEFR_ALL_WORDS]: t("marker_descriptions.all_words"),
    };

    const pickMarker = marker => () => {
        setMarker(marker);

        if (marker !== markerState.NONE) {
            gtmpushToDataLayer(marker, {
                user: userId,
                language: language,
                workspaceID: workspace,
            });
        }
    };
    const isActive = _marker =>
        _marker === marker ||
        // the NONE marker is active if this MarkerPicker doesn't have a button
        // for the selected marker
        (_marker === markerState.NONE && !markers.includes(marker));

    return (
        <div className="author-MarkerPicker">
            {markers.map(marker => (
                <div key={marker}>
                    <button
                        onClick={pickMarker(marker)}
                        className={isActive(marker) ? "active" : ""}
                        disabled={disabled}
                        name={marker}
                    >
                        {markerTitles[marker]}
                    </button>
                    <label>{markerDescriptions[marker]}</label>
                </div>
            ))}
        </div>
    );
};

MarkerPicker.propTypes = {
    disabled: PropTypes.bool,
    setMarker: PropTypes.func.isRequired,
    marker: PropTypes.oneOf(Object.keys(markerState)).isRequired,
    markers: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(markerState)))
        .isRequired,
};

export default MarkerPicker;
