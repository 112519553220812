import React from "react";
import Block from "../Block/Block";
import LinguisticsBlock from "./LinguisticsBlock";
import LinguisticsMarker from "./LinguisticsMarker";
import { useTranslation } from "react-i18next";

const LinguisticsContainer = ({
    userId,
    language,
    workspace,
    textStatistics = {},
    setMarker,
    wordStatistics = [],
    marker,
    isContentOutdated,
    characterCount,
    clearHighlight,
    hasPosTagsPermission,
    hasTextStatisticsPermission,
}) => {
    const { t } = useTranslation();

    const { wordCount = 0 } = textStatistics;

    return (
        <Block
            className="author-LinguisticsBlock"
            title={t("linguistic.title")}
            result={`${wordCount} ${t("linguistic.words")}`}
            isLoading={false}
        >
            {hasPosTagsPermission ? (
                <LinguisticsMarker
                    userId={userId}
                    language={language}
                    workspace={workspace}
                    setMarker={setMarker}
                    marker={marker}
                    isContentOutdated={isContentOutdated}
                    wordStatistics={wordStatistics}
                    clearHighlight={clearHighlight}
                />
            ) : null}
            {hasTextStatisticsPermission ? (
                <LinguisticsBlock
                    textStatistics={textStatistics}
                    characterCount={characterCount}
                />
            ) : null}
        </Block>
    );
};

export default LinguisticsContainer;
