import React from "react";
import { isFilledArray, markerState } from "../../config/helpers";
import { keywordText } from "../../config/helperTexts";
import BlockSection from "../Block/BlockSection";
import MarkerPicker from "../MarkerPicker/MarkerPicker";
import { useTranslation } from "react-i18next";

const KeywordMarker = ({
    userId,
    language,
    workspace,
    marker,
    isContentOutdated,
    keywords,
    clearHighlight,
    fetchKeywords,
}) => {
    const { t } = useTranslation();
    const actions = {
        [markerState.NONE]: clearHighlight,
        [markerState.KEYWORDS]: fetchKeywords,
    };
    const setMarker = marker => actions[marker] && actions[marker]();
    return (
        <BlockSection
            className="KeywordMarker"
            title={t("sidebar.areas_of_interest")}
            showHelp={true}
            explanation={t("cefr.keyword_explanation")}
        >
            <MarkerPicker
                userId={userId}
                language={language}
                workspace={workspace}
                marker={marker}
                markers={[markerState.NONE, markerState.KEYWORDS]}
                setMarker={setMarker}
                disabled={isContentOutdated || !isFilledArray(keywords)}
            />
        </BlockSection>
    );
};

export default KeywordMarker;
