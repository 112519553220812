import React, { Suspense } from "react";
import { render, hydrate } from "react-dom";
import Alert from "react-s-alert";
import { BrowserRouter, Route } from "react-router-dom";
import "./index.scss";
import App from "./components/App/App";
import TagManager from "react-gtm-module";

import "./i18n";

const tagManagerArgs = {
    gtmId: window?.papyrus?.GTM_ID,
    dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

const root = document.getElementById("root");

root.hasChildNodes()
    ? hydrate(
          <BrowserRouter>
              <Suspense fallback={"loading"}>
                  <Route path="/" component={App} />
                  <Alert stack={{ limit: 3 }} effect={"scale"} />
              </Suspense>
          </BrowserRouter>,
          root,
      )
    : render(
          <BrowserRouter>
              <Suspense fallback={"loading"}>
                  <Route path="/" component={App} />
                  <Alert stack={{ limit: 3 }} effect={"scale"} />
              </Suspense>
          </BrowserRouter>,
          root,
      );
