import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import papyrusLogo from "../../images/papyrus-logo.svg";
// import { handleOutboundLinkClicks } from "../../config/helpers";

import "../../styles/PageHeader.scss";
import NoConnection from "./NoConnection";

const PageHeader = ({ logout, showMenu }) => {
    const [isConnected, setIsConnected] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        // Remove the listeners on unMount
        return () => {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        };
    }, []);

    // reference is needed for unmount
    const handleOffline = () => setIsConnected(false);
    const handleOnline = () => setIsConnected(true);

    return (
        <div className="PageHeader">
            <NoConnection isConnected={isConnected} />
            <Link to="/">
                <img
                    src={papyrusLogo}
                    alt="Edia Papyrus logo"
                    className="PageHeader__logo"
                />
            </Link>
            <div
                className={`PageHeader__container ${
                    showMenu ? "PageHeader__loggedIn" : "PageHeader__login"
                }`}
            >
                {showMenu ? (
                    <>
                        {/* <Link
                            to="/feedback"
                            className="PageHeader__container__button"
                        >
                            Contact Us
                        </Link> */}
                        <button
                            className="PageHeader__container__button"
                            onClick={logout}
                        >
                            {t("navbar.exit")}
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            className="PageHeader__container__button"
                            onClick={event => {
                                event.preventDefault();
                                const apihost =
                                    window?.papyrus?.API_HOST ||
                                    "https://papyrus.edia.nl";
                                window.location.href =
                                    apihost + "/api/auth/login";
                            }}
                        >
                            {t("navbar.sign_in")} <b>Or</b>{" "}
                            {t("navbar.create_account")}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PageHeader;
