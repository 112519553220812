import React from "react";
import "../../styles/CefrPaper.scss";
import { Link } from "react-router-dom";
import Close from "../../images/x-mark.svg";
import PaperHeader from "./PaperHeader";
import HumanAnnotatorsIntro from "./HumanAnnotatorsIntro";
import HumanAnnotators from "./HumanAnnotators";
import TrainingData from "./TrainingData";
import AlgorithmAnnotator from "./AlgorithmAnnotator";
import AccuracyBars from "./AccuracyBars";
import LearnMore from "./LearnMore";
import Footnotes from "./Footnotes";
import PaperFooter from "./PaperFooter";

const CefrPaper = () => (
    <div className="CefrPaper">
        <Link to="/">
            <img src={Close} alt="close" className="CefrPaper__close" />
        </Link>
        <div className="CefrPaper__container">
            <PaperHeader />
            <HumanAnnotatorsIntro />
            <HumanAnnotators />
            <TrainingData />
            <AlgorithmAnnotator />
            <AccuracyBars />
            <LearnMore />
            <Footnotes />
            <PaperFooter />
        </div>
    </div>
);

export default CefrPaper;
