import React from "react";
import Microscope from "../../images/microscope.svg";
import Microscope_White from "../../images/microscope_white.svg";
import { useTranslation } from "react-i18next";

const AnalyseButton = ({ showWarning, getTextAnalysis, disabled }) => {
    const { t } = useTranslation();

    const dClassName = `author-AnalysisResult__action ${
        !disabled ? "warning" : ""
    }`;

    return (
        <button
            className={dClassName}
            onClick={getTextAnalysis}
            // disabled={disabled}
        >
            {showWarning ? (
                "⚠️️"
            ) : (
                <img
                    src={disabled ? Microscope : Microscope_White}
                    alt="micro"
                    className="author-AnalysisResult__action__image"
                />
            )}
            {showWarning
                ? ` ${t("sidebar.re_analyse_your_text")}`
                : t("sidebar.analyse_your_text")}
        </button>
    );
};

export default AnalyseButton;
