import React, { useState } from "react";
import { sendEmailNotificationMobile } from "../AuthorComponents";
import { useTranslation } from "react-i18next";

const Mobile = () => {
    const [email, setEmail] = useState("");
    const [emailRegistered, registerEmail] = useState(false);

    const { t } = useTranslation();

    const handleFormSubmit = async e => {
        e.preventDefault();
        const registered = await sendEmailNotificationMobile({ email });
        registerEmail(registered);
    };

    return (
        <section className="mobile">
            <h1>{t("landing.mobile_version_warning")}</h1>
            <p>
                <strong>{t("landing.mobile_usage")}</strong>{" "}
                {t("landing.mobile_inform")}
            </p>
            {emailRegistered ? (
                <span className="mobile__success">
                    {t("landing.email_registered_success")}
                </span>
            ) : (
                <form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <label>
                            <span>{t("landing.email_address_label")}</span>
                            <input
                                onChange={e => setEmail(e.currentTarget.value)}
                                placeholder={t(
                                    "landing.email_address_placeholder",
                                )}
                            />
                        </label>
                    </fieldset>
                    <button type="submit">{t("landing.notify_me")}</button>
                </form>
            )}
        </section>
    );
};

export default Mobile;
