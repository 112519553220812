import React from "react";

import {
    halfLevelArray,
    colorOpacity,
    markerState,
} from "../../config/helpers";

import "../../style/components/CefrProbabilities.css";
import BlockSection from "../Block/BlockSection";
import { useTranslation } from "react-i18next";

const getClassName = (itemNumber, targetLevel, marker) =>
    `author-CefrProbabilities__name ${
        itemNumber === targetLevel && marker !== markerState.CEFR_ALL_WORDS
            ? "author-CefrProbabilities__name--selected"
            : ""
    }`;

const CefrProbabilities = ({
    cefrLevel = "A1",
    targetLevel,
    cefrNumeric,
    marker,
    setTargetLevel,
}) => {
    const { t } = useTranslation();

    const handleTargetLevelChange = e => {
        e.preventDefault();
        //Event does not have value so set item number on id intead
        setTargetLevel(Number(e.currentTarget.id));
    };

    if (!cefrNumeric) return null;

    return (
        <BlockSection
            className="author-CefrProbabilities"
            title={t("cefr.cefr_scale_title")}
            showHelp={true}
            explanation={t("cefr.cefr_scale_explanation")}
        >
            <table>
                <tbody>
                    <ActualLevelRow
                        cefrLevel={cefrLevel}
                        cefrVersionArray={halfLevelArray}
                        cefrNumeric={cefrNumeric}
                    />
                    <CefrScale
                        targetLevel={targetLevel}
                        cefrVersionArray={halfLevelArray}
                        marker={marker}
                        handleTargetLevelChange={handleTargetLevelChange}
                    />
                    <TargetLevelRow
                        targetLevel={targetLevel}
                        cefrVersionArray={halfLevelArray}
                    />
                </tbody>
            </table>
        </BlockSection>
    );
};

export default CefrProbabilities;

/**
 * Create top row in table to show Actual Level along the bar
 */
export const ActualLevelRow = ({
    cefrLevel,
    cefrVersionArray,
    cefrNumeric,
}) => {
    const { t } = useTranslation();

    return (
        <tr>
            {cefrVersionArray.map((item, i) => (
                <td className="tooltip__row" key={item.name + i}>
                    {cefrLevel === item.name ? (
                        <div className="tooltip">
                            <span
                                className="tooltip__text"
                                style={calcPosition(item.number, cefrNumeric)}
                            >
                                {t("cefr.actual_level")}
                            </span>
                            <span
                                className="tooltip__position"
                                style={calcPosition(item.number, cefrNumeric)}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </td>
            ))}
        </tr>
    );
};

export const calcPosition = (cefrValue, cefrNumeric) => {
    let percentage = (cefrNumeric - cefrValue) * 100;
    return { left: `${percentage}%` };
};

/**
 * Create row displaying the different possible CEFR levels including the target level being selected
 */

export const CefrScale = ({
    targetLevel,
    cefrVersionArray,
    marker,
    handleTargetLevelChange,
}) => (
    <tr>
        {cefrVersionArray.map(item => (
            <td
                key={item.name}
                className={getClassName(item.number, targetLevel, marker)}
                style={calcBgColor(item.number, targetLevel, marker)}
                id={item.number}
                onClick={handleTargetLevelChange}
            >
                {item.name}
            </td>
        ))}
    </tr>
);

const calcBgColor = (actual, target, marker) => {
    let backgroundColor;

    if (marker === markerState.CEFR_ALL_WORDS) {
        backgroundColor = halfLevelArray[actual].color;
    } else if (actual === target) {
        backgroundColor = `#222222`;
    } else if (actual > target && marker === markerState.ABOVE_TARGET) {
        backgroundColor = `rgba(255,0,0, ${colorOpacity(actual, target)})`;
    }

    return { backgroundColor };
};

/**
 * Create bottom row to show target level label
 */

export const TargetLevelRow = ({ cefrVersionArray, targetLevel }) => {
    const { t } = useTranslation();

    return (
        <tr>
            {cefrVersionArray.map((item, i) => (
                <td key={item.name + i} className="name__cell">
                    {targetLevel === item.number ? (
                        <span className="name__target">
                            {t("cefr.target_level")}
                        </span>
                    ) : (
                        ""
                    )}
                </td>
            ))}
        </tr>
    );
};
