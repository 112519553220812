import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// TODO: cleanup: use array
const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="Mainpage__footer">
            <div>
                <ul>
                    <li>
                        <a
                            href="https://www.edia.nl/privacy"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t("landing.privacy_policy")}
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.edia.nl/security"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t("landing.security_policy")}
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a
                            href="https://www.edia.nl"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t("landing.about_us")}
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a
                            href="https://www.edia.nl/contact"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t("landing.contact_us")}
                        </a>
                    </li>
                </ul>
            </div>
            <h1 className="Mainpage__copyright">
                &copy; {t("landing.copyright")} {new Date().getFullYear()}
            </h1>
        </footer>
    );
};

export default Footer;
