import React from "react";
import PasswordEnterEmail from "./PasswordEnterEmail";
import PasswordSendEmail from "./PasswordSendEmail";
import { sendResetEmail } from "../AuthorComponents";

import "../../styles/PasswordEnterEmail.scss";

class PasswordEmail extends React.Component {
    state = {
        emailSend: false,
        sending: false,
        email: "",
    };

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ sending: true });
        const emailSend = await sendResetEmail(this.state.email);
        this.setState({ emailSend, sending: false });
    };

    render() {
        return !this.state.emailSend ? (
            <PasswordEnterEmail
                sending={this.state.sending}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
            />
        ) : (
            <PasswordSendEmail />
        );
    }
}

export default PasswordEmail;
