import React from "react";
import BlockHeader from "../Block/BlockHeader";

import "../../style/components/Block.css";

class Block extends React.Component {
    state = {
        showMore: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result && !this.props.close) {
            this.setState({ showMore: true });
        }
    }

    toggleShowMore = () => {
        this.setState({ showMore: !this.state.showMore });
    };

    render() {
        const {
            children,
            title,
            result,
            className = "",
            isLoading,
        } = this.props;

        return (
            <div className={`author-Block ${className}`}>
                <BlockHeader
                    showMore={this.state.showMore}
                    toggleShowMore={this.toggleShowMore}
                    result={result}
                    title={title}
                    isLoading={isLoading}
                />

                <div
                    className="author-Block__content"
                    style={
                        this.state.showMore
                            ? { height: "auto" }
                            : { height: "0px" }
                    }
                >
                    {isLoading ? (
                        <div className="author-Block__overlay" />
                    ) : null}
                    {children}
                </div>
            </div>
        );
    }
}

export default Block;
