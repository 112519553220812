import React from "react";
import "../../style/components/TopicBlock.css";
import Block from "../Block/Block";
import KeywordMarker from "./KeywordMarker";
import KeywordBlock from "./KeywordBlock";
import TopicBlock from "./TopicBlock";
import { useTranslation } from "react-i18next";

const TopicContainer = ({
    userId,
    language,
    workspace,
    isLoading,
    topics,
    keywords,
    marker,
    isContentOutdated,
    clearHighlight,
    fetchKeywords,
    setMarker,
    setKeywordToken,
    keywordToken,
    hasTopicsPermission,
    hasKeywordsPermission,
    hasWordDisambiguationPermission,
}) => {
    const { t } = useTranslation();
    // Extract the most likely topic (first item of first topic array) to show in block header
    const mostLikelyTopic = topics && topics[0];

    return (
        <Block
            className="author-TopicBlock"
            title={t("topic.semantic")}
            result={mostLikelyTopic && mostLikelyTopic.topics[0]}
            isLoading={isLoading}
        >
            {hasKeywordsPermission || hasWordDisambiguationPermission ? (
                <KeywordMarker
                    userId={userId}
                    language={language}
                    workspace={workspace}
                    marker={marker}
                    isContentOutdated={isContentOutdated}
                    keywords={keywords}
                    clearHighlight={clearHighlight}
                    fetchKeywords={fetchKeywords}
                />
            ) : null}
            {hasKeywordsPermission ? (
                <KeywordBlock
                    keywords={keywords}
                    setMarker={setMarker}
                    setKeywordToken={setKeywordToken}
                    keywordToken={keywordToken}
                    isContentOutdated={isContentOutdated}
                />
            ) : null}
            {hasTopicsPermission ? <TopicBlock topics={topics} /> : null}
        </Block>
    );
};

export default TopicContainer;
