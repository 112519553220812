import React from "react";
import Chart from "../../images/cefr_accurate.png";
import { useTranslation } from "react-i18next";

const AlgorithmAnnotator = () => {
    const { t } = useTranslation();

    const barText = {
        __html: t("landing.bar_text"),
    };

    return (
        <section className="CefrPaper__algorithm">
            <div className="CefrPaper__algorithm__container">
                <img src={Chart} alt="chart" />
            </div>
            <div className="CefrPaper__algorithm__block">
                <h3>{t("landing.algorithm_annotator")}</h3>
                {/* Done this way because of html symbols that require processing. 
            It's safe because we control the input */}
                <p dangerouslySetInnerHTML={barText} />
            </div>
        </section>
    );
};

export default AlgorithmAnnotator;
