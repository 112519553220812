import React from "react";
import "../../../style/components/HighlightPopup.css";

const HighlightPopup = ({ close, children, title }) => (
    <div className="author-HighlightPopup">
        <div className="author-HighlightPopup__topbar">
            <span className="author-HighlightPopup__title">{title}</span>
            <span className="u-clickable pe-7s-close-circle" onClick={close} />
        </div>
        <div className="author-HighlightPopup__content">{children}</div>
    </div>
);

export default HighlightPopup;
