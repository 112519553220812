// import Popper from "popper.js";
import { createPopper } from "@popperjs/core";
import React from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import HighlightPopup from "./HighlightPopup";

// Once you pass on a custom format, you'll need to explicitly pass on the defaults as well
// if you want all text formatting features to work
export const formats = [
    // default formats
    "align",
    "direction",
    "indent",
    "background",
    "color",
    "font",
    "size",
    "blockquote",
    "code-block",
    "header",
    "list",
    "bold",
    "code",
    "italic",
    "link",
    "script",
    "strike",
    "underline",
    "image",
    "video",
    "list/item",

    // custom formats
    "formats/div",
    "div",
];

const Inline = ReactQuill.Quill.import("blots/inline");

// keep track of the currently opened popper
// so we can close it at an arbitrary moment, e.g. when opening a new popper
let currentPopper;

const deleteChildElement = () => {
    try {
        let element = document.querySelector(`.author-TextEditor__poppers`);
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    } catch (e) {
        console.log("Error deleting element: ", e);
    }
};

export const closeHighlightPopper = () => {
    if (!currentPopper) {
        return;
    }

    currentPopper.destroy();
    currentPopper = undefined;

    // delete the child divs - currently it seems like only the styles are removed, but not
    // the entire div
    // so let's clear the child divs
    deleteChildElement();
};

class Highlight extends Inline {
    static blotName = "div";
    static tagName = "div";
    static className = "author-highlight";

    static create(value) {
        const $node = super.create(true);

        Object.assign($node.style, this.getStyleRules(value));

        if (value.renderPopup) {
            $node.addEventListener("click", this.handleClick($node, value));
        }
        return $node;
    }

    static replaceText(value) {
        return value.replaceText;
    }

    static getStyleRules = ({ color, renderPopup, style = {} }) => ({
        // first set transparant background collor from showHighlighting function,
        // then overwrite background color with actual color if highlighting words
        ...style,
        backgroundColor: color || style.backgroundColor,
        cursor: renderPopup ? "pointer" : "",
    });

    static handleClick($node, value) {
        const { renderPopup, text } = value;

        return () => {
            // close currently opened popper when we click the same highlight again
            if (currentPopper && currentPopper.reference === $node) {
                closeHighlightPopper();
                return;
            }

            // close currently opened popper when we open one
            closeHighlightPopper();

            // element the popper will be rendered in
            const $popper = document.createElement("DIV");
            // TODO pass container element as part of value in create()
            document
                .querySelector(`.author-TextEditor__poppers`)
                .appendChild($popper);

            // keep track of the currently opened popper so we can close it
            // when opening another popper
            // currentPopper = new Popper($node, $popper, {
            //     removeOnDestroy: true,
            // });

            currentPopper = createPopper($node, $popper, {
                removeOnDestroy: true,
            });

            const popupProps = {
                replace: this.replaceText(value),
                text,
                popper: currentPopper,
            };

            // render the content of the popup into the popper
            // using a react component
            ReactDOM.render(
                <HighlightPopup
                    close={closeHighlightPopper}
                    title={$node.innerText}
                >
                    {renderPopup(popupProps)}
                </HighlightPopup>,
                $popper,
            );
        };
    }
}

ReactQuill.Quill.register({ "formats/div": Highlight });
