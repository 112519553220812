import React from "react";
import linkedInIcon from "../../images/linkedin_icon.svg";
import twitterIcon from "../../images/twitter_icon.svg";
import fbIcon from "../../images/fb_icon.svg";
import { useTranslation } from "react-i18next";

const LearnMore = () => {
    const { t } = useTranslation();

    return (
        <section className="CefrPaper__more">
            <h2>{t("landing.want_to_learn")}</h2>
            <span>
                {t("landing.register_for_free")}{" "}
                <span className="CefrPaper__more__arrow">&rarr;</span>
                <a
                    href="https://papyrus.edia.nl/"
                    alt="papyrus link"
                    className="CefrPaper__more__homepage"
                >
                    <strong>https://papyrus.edia.nl</strong>
                </a>
            </span>
            <div>
                {LinkList.map(item => (
                    <a
                        key={item.url}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img src={item.image} alt="email" />
                        {item.title}
                    </a>
                ))}
            </div>
        </section>
    );
};

export default LearnMore;

const LinkList = [
    {
        url:
            "https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=http%3A%2F%2Fbit.ly%2F2K0s3ws&display=popup&ref=plugin",
        image: fbIcon,
        title: "Facebook",
    },
    {
        url:
            "https://twitter.com/intent/tweet?text=Write%20quality%20articles%20in%20less%20time%20with%20Papyrus%20%40_edia&url=http://bit.ly/2K0s3ws",
        image: twitterIcon,
        title: "Twitter",
    },
    {
        url: "https://nl.linkedin.com/company/edia---education-technology",
        image: linkedInIcon,
        title: "LinkedIn",
    },
];
