import React, { Component } from "react";
import Alert from "react-s-alert";
import AnalyseButton from "./components/AnalyseButton/AnalyseButton";
import AnalysisResultContainer from "./components/AnalysisResult/AnalysisResultContainer";
import CefrSettings from "./components/AnalysisResult/CefrSettings";
import Header from "./components/Header/Header";
import LinguisticsContainer from "./components/LinguisticsBlock/LinguisticsContainer";
import ReadabilityContainer from "./components/Readability/ReadabilityContainer";
import SessionEnded from "./components/Session/SessionEnded";
import TopicContainer from "./components/TopicBlock/TopicContainer";
import {
    fetchCefrLevel,
    fetchKeywords,
    fetchLinguistics,
    fetchReadability,
    fetchTopics,
    fetchUser,
    fetchWordDifficulty,
} from "./config/Edia360ApiClient";

import {
    CEFR_LEVELS,
    isFilledArray,
    markerState,
    userHasPermission,
    modules,
    gtmpushToDataLayer,
} from "./config/helpers";
import "./style/style.css";
import { withTranslation } from "react-i18next";

const DEFAULT_SESSION_LIFETIME = 1000 * 60 * 25; // 25m
const REMAINING_TIME = 60;
const ONESECOND = 1000;

class Edia360AuthorComponents extends Component {
    sessionTimeout;
    timerInterval;

    state = {
        cefr: {
            name: "N/A",
        },
        numeric: null,
        gse: "N/A",
        probabilities: [],
        readability: [],
        tokenDifficulties: [],
        topics: [],
        textStatistics: {},
        keywords: [],
        characterCount: 0,
        //classLevel is static at the moment
        classLevel: CEFR_LEVELS.LEVEL_9_CLASS,
        language: "",
        targetLevel: 3,
        isLoading: false,
        isButtonLoading: false,
        sessionEnded: false,
        remainingTime: REMAINING_TIME,
        hasReadabilityScorePermission: false,
        hasPosTagsPermission: false,
        hasTextStatisticsPermission: false,
        hasTopicsPermission: false,
        hasKeywordsPermission: false,
        hasWordDisambiguationPermission: false,
        hasCefrScalePermission: false,
        hasDifficultWordsPermission: false,
        hasGseScalePermission: false,
        hasReaderComprehension: false,
    };

    loadPermissions = () => {
        if (this.props.permissions) {
            const hasReadabilityScorePermission = userHasPermission(
                modules.READABILITY_SCORE,
                this.props.permissions,
            );
            const hasPosTagsPermission = userHasPermission(
                modules.POS_TAGS,
                this.props.permissions,
            );
            const hasTextStatisticsPermission = userHasPermission(
                modules.TEXT_STATISTICS,
                this.props.permissions,
            );
            const hasTopicsPermission = userHasPermission(
                modules.TOPICS,
                this.props.permissions,
            );
            const hasKeywordsPermission = userHasPermission(
                modules.KEYWORDS,
                this.props.permissions,
            );
            const hasWordDisambiguationPermission = userHasPermission(
                modules.WORD_DISAMBIGUATIONS,
                this.props.permissions,
            );
            const hasCefrScalePermission = userHasPermission(
                modules.CEFR_SCALE,
                this.props.permissions,
            );
            const hasDifficultWordsPermission = userHasPermission(
                modules.DIFFICULT_WORDS,
                this.props.permissions,
            );
            const hasGseScalePermission = userHasPermission(
                modules.GSE_SCALE,
                this.props.permissions,
            );
            const hasReaderComprehension = userHasPermission(
                modules.READER_COMPREHENSION,
                this.props.permissions,
            );
            this.setState({
                hasReadabilityScorePermission,
                hasPosTagsPermission,
                hasTextStatisticsPermission,
                hasTopicsPermission,
                hasKeywordsPermission,
                hasWordDisambiguationPermission,
                hasCefrScalePermission,
                hasDifficultWordsPermission,
                hasGseScalePermission,
                hasReaderComprehension,
            });
        }
    };

    componentDidMount() {
        this.loadPermissions();
        this.getLastTextAnalysis();
        this.resetSessionTimeout();
        this.props.setPreferredLanguage(this.state.language);
    }

    componentWillUnmount() {
        clearInterval(this.timerInterval);
        clearInterval(this.sessionTimeout);
    }

    componentDidUpdate({ endSession, permissions }) {
        this.props.endSession !== endSession && this.showSessionModal();

        // Hot fix: prevent too many request message when fetching new highlights on typing
        //TODO: Creat long term fix
        // if (highlightEdit) {
        //     const text = getText();
        //     if (this.state.text !== text) {
        //         this.getGseWordLevel();
        //         this.setState({ text });
        //     }
        // }
    }

    resetSessionTimeout = () => {
        const { sessionLifetime = DEFAULT_SESSION_LIFETIME } = this.props;

        clearTimeout(this.sessionTimeout);
        clearInterval(this.timerInterval);
        this.sessionTimeout = setTimeout(
            this.showSessionModal,
            sessionLifetime,
        );
        this.setState({ sessionEnded: false });
    };

    showSessionModal = () => {
        this.setState(() => ({
            sessionEnded: true,
            remainingTime: REMAINING_TIME,
        }));

        clearInterval(this.timerInterval);
        this.timerInterval = setInterval(this.setRemainingTime, ONESECOND);
    };

    setRemainingTime = () => {
        let { remainingTime } = { ...this.state };
        if (remainingTime < 1) {
            clearInterval(this.timerInterval);
            this.props.onSessionTimeout();
            return;
        }

        this.setState({
            remainingTime: --remainingTime,
        });
    };

    extendSession = async () => {
        await fetchUser();
        this.resetSessionTimeout();
    };

    getTextAnalysis = () => {
        this.resetSessionTimeout();

        const { getText } = this.props;

        if (getText().length <= 0) return;

        this.showExceedLimitWarning();

        this.setState({ isButtonLoading: true });

        Promise.all([
            this.storeText(),
            this.fetchTopics(),
            this.getCefrLevel(),
            this.getReadability(),
            this.fetchKeywords(),
            this.fetchLinguistics(),
        ])
            .then(res => {
                this.setState({ isButtonLoading: false });
            })
            .catch(err => {
                console.error(err);
                this.setState({ isButtonLoading: false });
            });

        this.getCharacterLength();

        this.props.setMarker(markerState.NONE);
    };

    showExceedLimitWarning = () => {
        const { limits, t } = this.props;

        const textLength = this.props.getTextLength();
        //text length is compared to character limit
        //limit is 5000 excluding spaces
        const characterLimit =
            limits && limits["author-character-limit"]
                ? limits["author-character-limit"]
                : 1000;
        if (textLength >= characterLimit) {
            return Alert.info(
                t("app_errors.character_limit", {
                    character_limit: characterLimit,
                }),
            );
        }
    };

    showLanguageWarning = code => {
        return Alert.info(
            this.props.t("app_errors.language_warning", {
                code: code,
            }),
        );
    };

    getCharacterLength = () => {
        const { getTextLength } = this.props;
        const characterCount = getTextLength();
        this.setState({ characterCount });
    };

    //Get last analysed text of user on login
    // No checks here because fetch always returns a default result

    getLastTextAnalysis = async () => {
        try {
            // retrieve last text and target level
            await this.retrieveLastText();
            await this.getTextAnalysis();
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    //isLoading is only set on getCefrLevel for now since this is the slowest request
    getCefrLevel = async () => {
        try {
            const { getText } = this.props;
            const { classLevel, targetLevel } = this.state;
            this.setState({
                isLoading: true,
                tokenDifficulties: [],
            });
            const text = getText();
            const payload = await fetchCefrLevel(classLevel, text, targetLevel);
            this.setState({
                ...payload,
                isLoading: false,
                lastAnalysedText: text,
            });

            const { userId, workspace, getTextLength } = this.props;
            gtmpushToDataLayer("analyse_text", {
                textSize: getTextLength(),
                cefr: payload && payload.cefr && payload.cefr.name,
                language: payload && payload.language_detected,
                workspaceID: workspace,
                user: userId,
            });

            // pre-load gse data
            const { tokenDifficulties } = await fetchWordDifficulty(
                text,
                classLevel,
            );

            this.setState({
                tokenDifficulties,
            });
            this.props.setTokenDifficulties(tokenDifficulties);
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    getReadability = async () => {
        try {
            const { targetLevel } = this.state;
            const data = await fetchReadability(
                this.props.getText(),
                targetLevel,
            );
            this.setState({
                readability: data.readabilityScores,
            });
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    /**
     * Get the text entered by the user
     * and highlight words according
     * to difficulty
     */
    getGseWordLevel = async () => {
        try {
            const {
                getText,
                isContentOutdated,
                setTokenDifficulties,
                setMarker,
            } = this.props;
            const { targetLevel, tokenDifficulties, classLevel } = this.state;

            this.resetSessionTimeout();

            if (
                !this.state.isLoading &&
                (!isFilledArray(tokenDifficulties) || isContentOutdated)
            ) {
                // get text from text editor
                this.setState({ isLoading: true });
                const { tokenDifficulties } = await fetchWordDifficulty(
                    getText(),
                    classLevel,
                );
                setTokenDifficulties(tokenDifficulties);

                this.setState({
                    tokenDifficulties,
                    isLoading: false,
                });

                /*
                    update cefr level too in next event loop
                    allowing phrase highlighting asap
                */
                setTimeout(async () => {
                    const payload = await fetchCefrLevel(
                        classLevel,
                        getText(),
                        targetLevel,
                    );
                    this.setState({ ...payload });
                }, 0);
            } else {
                setTokenDifficulties(tokenDifficulties);
            }
            setMarker(markerState.ABOVE_TARGET);
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    getCefrLevelForAllWords = async () => {
        try {
            const {
                getText,
                isContentOutdated,
                setTokenDifficulties,
                setMarker,
            } = this.props;
            const { targetLevel, tokenDifficulties, classLevel } = this.state;

            this.resetSessionTimeout();

            if (
                !this.state.isLoading &&
                (!isFilledArray(tokenDifficulties) || isContentOutdated)
            ) {
                // get text from text editor
                this.setState({ isLoading: true });
                const { tokenDifficulties } = await fetchWordDifficulty(
                    getText(),
                    classLevel,
                );
                setTokenDifficulties(tokenDifficulties);

                this.setState({
                    tokenDifficulties,
                    isLoading: false,
                });

                /*
                    update cefr level too in next event loop
                    allowing phrase highlighting asap
                */
                setTimeout(async () => {
                    const payload = await fetchCefrLevel(
                        classLevel,
                        getText(),
                        targetLevel,
                    );
                    this.setState({ ...payload });
                }, 0);
            } else {
                setTokenDifficulties(tokenDifficulties);
            }
            setMarker(markerState.CEFR_ALL_WORDS);
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    // I ve added this so as to trigger pos tags
    getWordStatistics = async () => {
        try {
            const {
                getText,
                isContentOutdated,
                setWordStatistics,
                setMarker,
            } = this.props;
            const { wordStatistics, targetLevel } = this.state;

            this.resetSessionTimeout();

            if (
                !this.state.isLoading &&
                (!isFilledArray(wordStatistics) || isContentOutdated)
            ) {
                // get text from text editor
                this.setState({ isLoading: true });
                const { wordStatistics } = await fetchLinguistics(
                    getText(),
                    targetLevel,
                );
                setWordStatistics(wordStatistics);

                this.setState({
                    wordStatistics,
                    isLoading: false,
                });
            } else {
                setWordStatistics(wordStatistics);
            }
            setMarker(markerState.POSTAGS);
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    clearHighlight = () => {
        this.props.setMarker(markerState.NONE);
        this.props.clearKeywordToken();
    };

    setLanguage = language => {
        this.setState({ language });
        this.props.setPreferredLanguage(language);
    };

    setTargetLevel = targetLevel => {
        this.setState({ targetLevel });
        this.props.retrieveTargetLevel(targetLevel);
    };

    fetchTopics = async () => {
        try {
            const { getText } = this.props;
            const { targetLevel } = this.state;
            const { topics, language, language_detected } = await fetchTopics(
                getText(),
                targetLevel,
            );

            this.setState({ topics });
            this.setLanguage(language_detected);

            if (language !== language_detected && language_detected) {
                this.showLanguageWarning(language_detected);
            }
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    fetchKeywords = async () => {
        try {
            const { getText, setKeywords } = this.props;
            const { targetLevel } = this.state;
            const { keywords } = await fetchKeywords(getText(), targetLevel);
            setKeywords(keywords);
            this.setState({
                keywords,
            });
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    fetchLinguistics = async () => {
        try {
            const { getText, setWordStatistics } = this.props;
            const { targetLevel } = this.state;
            const { textStatistics, wordStatistics } = await fetchLinguistics(
                getText(),
                targetLevel,
            );
            setWordStatistics(wordStatistics);
            this.setState({ textStatistics });
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    getStorageName = () => {
        return "papyrus_storage_" + this.props.userId;
    };

    storeText = async () => {
        try {
            const { getText } = this.props;
            const { targetLevel } = this.state;
            const payload = { targetLevel, text: getText() };
            localStorage.setItem(
                this.getStorageName(),
                JSON.stringify(payload),
            );
        } catch (e) {
            console.error("Exception e", e);
        }
    };

    retrieveLastText = async () => {
        try {
            const items = JSON.parse(
                localStorage.getItem(this.getStorageName()),
            );
            if (items) {
                const { text, targetLevel } = items;
                this.props.getLastAnalysedText(text, targetLevel);
            }
        } catch (e) {
            console.error("Exception e", e);
        }
    };

    getKeywords = async () => {
        try {
            const {
                getText,
                setMarker,
                setKeywords,
                isContentOutdated,
            } = this.props;
            const { targetLevel, keywords, isLoading } = this.state;

            if (!isLoading && (!isFilledArray(keywords) || isContentOutdated)) {
                // get text from text editor
                this.setState({ isLoading: true });
                const { keywords } = await fetchKeywords(
                    getText(),
                    targetLevel,
                );

                setKeywords(keywords);

                this.setState({
                    keywords,
                    isLoading: false,
                });
            } else {
                setKeywords(keywords);
            }
            setMarker(markerState.KEYWORDS);
        } catch (e) {
            console.error("Exception e: ", e);
        }
    };

    render() {
        const { isContentOutdated } = this.props;
        const {
            isButtonLoading,
            lastAnalysedText,
            targetLevel,
            sessionEnded,
            remainingTime,
            hasReadabilityScorePermission,
            hasPosTagsPermission,
            hasTextStatisticsPermission,
            hasTopicsPermission,
            hasKeywordsPermission,
            hasWordDisambiguationPermission,
            hasCefrScalePermission,
            hasDifficultWordsPermission,
            hasGseScalePermission,
            hasReaderComprehension,
        } = this.state;
        const trimmedText = this.props.getText().trim();

        return (
            <div className="e360-app__container">
                {this.state.sessionEnded ? (
                    <SessionEnded
                        {...this.props}
                        sessionEnded={sessionEnded}
                        extendSession={this.extendSession}
                        remainingTime={remainingTime}
                    />
                ) : (
                    <div className="e360-app__wrapper">
                        <Header />
                        <CefrSettings
                            targetLevel={targetLevel}
                            selectTargetLevel={this.setTargetLevel}
                        />
                        <AnalyseButton
                            {...this.props}
                            disabled={
                                isButtonLoading ||
                                !trimmedText ||
                                !trimmedText ||
                                !isContentOutdated
                            }
                            showWarning={
                                lastAnalysedText &&
                                lastAnalysedText.trim() &&
                                isContentOutdated &&
                                trimmedText
                            }
                            getTextAnalysis={this.getTextAnalysis}
                            data-ftest-id="cefr toggle"
                        />

                        {hasCefrScalePermission ||
                        hasDifficultWordsPermission ||
                        hasGseScalePermission ||
                        hasReaderComprehension ? (
                            <AnalysisResultContainer
                                {...this.props}
                                {...this.state}
                                getGseWordLevel={this.getGseWordLevel}
                                getCefrLevelForAllWords={
                                    this.getCefrLevelForAllWords
                                }
                                clearHighlight={this.clearHighlight}
                                setTargetLevel={this.setTargetLevel}
                                hasCefrScalePermission={hasCefrScalePermission}
                                hasDifficultWordsPermission={
                                    hasDifficultWordsPermission
                                }
                                hasGseScalePermission={hasGseScalePermission}
                                hasReaderComprehension={hasReaderComprehension}
                            />
                        ) : null}

                        {hasTopicsPermission ||
                        hasKeywordsPermission ||
                        hasWordDisambiguationPermission ? (
                            <React.Fragment>
                                <TopicContainer
                                    {...this.state}
                                    {...this.props}
                                    keywordToken={this.props.keywordToken}
                                    keywords={this.props.keywords}
                                    fetchKeywords={this.getKeywords}
                                    setKeywordToken={this.props.setKeywordToken}
                                    setMarker={this.props.setMarker}
                                    clearHighlight={this.clearHighlight}
                                    hasTopicsPermission={hasTopicsPermission}
                                    hasKeywordsPermission={
                                        hasKeywordsPermission
                                    }
                                    hasWordDisambiguationPermission={
                                        hasWordDisambiguationPermission
                                    }
                                />
                            </React.Fragment>
                        ) : null}
                        {hasPosTagsPermission || hasTextStatisticsPermission ? (
                            <LinguisticsContainer
                                {...this.state}
                                {...this.props}
                                getWordStatistics={this.getWordStatistics}
                                textStatistics={this.state.textStatistics}
                                clearHighlight={this.clearHighlight}
                                characterCount={this.state.characterCount}
                                hasPosTagsPermission={hasPosTagsPermission}
                                hasTextStatisticsPermission={
                                    hasTextStatisticsPermission
                                }
                            />
                        ) : null}
                        {hasReadabilityScorePermission ? (
                            <ReadabilityContainer
                                {...this.state}
                                {...this.props}
                            />
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(Edia360AuthorComponents);

export { default as TextEditor } from "./components/TextEditor/TextEditor";
export { CEFR_LEVELS, markerState } from "./config/helpers";
export {
    fetchUser,
    logout,
    login,
    register,
    sendResetEmail,
    resetPassword,
    sendFeedback,
    updateUser,
    sendSupportQuestion,
    sendEmailNotificationMobile,
} from "./config/Edia360ApiClient";
