import React from "react";
import "../../styles/LandingPage.scss";
import { useTranslation } from "react-i18next";

const LandingImage1 = () => {
    const { t } = useTranslation();

    return (
        <div className="LandingImage1">
            <header>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </header>
            <article>
                <p>
                    <span className="hard">{t("landing.difficult")}</span>{" "}
                    {t("landing.wording")}, {t("landing.highlight_in")}{" "}
                    <span className="hard">{t("landing.color_red")}</span> , is{" "}
                    <span className="hard">{t("landing.above")}</span>{" "}
                    {t("landing.the_target")}{" "}
                    <span className="hard">{t("cefr.cefr_title")}</span>{" "}
                    {t("landing.level")}.{" "}
                    <span className="hard">{t("landing.now")}</span>{" "}
                    {t("landing.you_can")}{" "}
                    <span className="hard">{t("landing.recognise")}</span>{" "}
                    {t("landing.difficult_words")}{" "}
                    <span className="hard">{t("landing.instantly")}</span>.{" "}
                </p>

                <aside>
                    <div className="cefr-score">
                        <label>{t("landing.cefr_score")}</label>
                        A2+
                    </div>
                    <button>{t("landing.analyse")}</button>
                    <div className="mouse-pointer">
                        <svg
                            version="1.1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 28 28"
                            enableBackground="new 0 0 28 28"
                        >
                            <polygon
                                fill="#FFFFFF"
                                points="8.2,20.9 8.2,4.9 19.8,16.5 13,16.5 12.6,16.6 "
                            />
                            <polygon
                                fill="#FFFFFF"
                                points="17.3,21.6 13.7,23.1 9,12 12.7,10.5 "
                            />
                            <rect
                                x="12.5"
                                y="13.6"
                                transform="matrix(0.9221 -0.3871 0.3871 0.9221 -5.7605 6.5909)"
                                width="2"
                                height="8"
                            />
                            <polygon points="9.2,7.3 9.2,18.5 12.2,15.6 12.6,15.5 17.4,15.5 " />
                        </svg>
                    </div>
                </aside>
            </article>
        </div>
    );
};

export default LandingImage1;
