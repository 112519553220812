import React from "react";

import "../../style/components/BlockHeader.css";
import Loader from "./Loader";

const BlockHeader = ({
    showMore,
    title,
    result,
    isLoading,
    toggleShowMore,
}) => {
    const getClassName = showMore
        ? "pe-7s-angle-up-circle"
        : "pe-7s-angle-down-circle";

    const whatToShow = (result, isLoading) => {
        if (isLoading) {
            return <Loader />;
        } else if (result === "N/A") {
            return "";
        } else {
            return result;
        }
    };

    const getStyle = result =>
        result != null && result !== "N/A"
            ? { fontSize: "36px" }
            : { fontSize: "36px", color: "grey" };

    return (
        <div className="author-BlockHeader__header" onClick={toggleShowMore}>
            <span className="author-BlockHeader__header__title">{title}</span>
            <span className="author-BlockHeader__header__level">
                {whatToShow(result, isLoading)}
            </span>
            <span
                className={getClassName}
                style={getStyle(result)}
                name="toggleButton"
            />
        </div>
    );
};

export default BlockHeader;
