import React from "react";
import "../../../style/components/KeywordPopup.css";
import { useTranslation } from "react-i18next";

const KeywordPopup = ({ keyword, language }) => {
    const { t } = useTranslation();

    const wikipediaUrl = `http://${language}.wikipedia.org/wiki/${keyword.lemma}`;

    return (
        <div className="author-KeywordPopup">
            <table>
                <tbody>
                    <tr>
                        <td>{t("editor.lemma")}:</td>
                        <td>{keyword.lemma}</td>
                    </tr>
                    <tr>
                        <td>{t("editor.token")}:</td>
                        <td>{keyword.token}</td>
                    </tr>
                    <tr>
                        <td>{t("editor.certainity")}:</td>
                        <td>{keyword && keyword.certainty.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <a
                                href={wikipediaUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {wikipediaUrl}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default KeywordPopup;
