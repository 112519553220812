import React from "react";
import Check from "../../images/checkmark.svg";
import CefrChart from "../../images/cefr_chart.svg";
import { useTranslation } from "react-i18next";

const AccuracyBars = () => {
    const { t } = useTranslation();

    const listItems = [
        t("landing.accurately"),
        t("landing.consistently"),
        t("landing.faster"),
    ];

    return (
        <section className="CefrPaper__accuracy">
            <div className="CefrPaper__accuracy__text">
                <span>{t("landing.classifies_text")}</span>
                <ul>
                    {listItems.map(item => (
                        <li key={item}>
                            <img
                                src={Check}
                                alt="checkmark"
                                className="CefrPaper__accuracy__check"
                            />
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="CefrPaper__accuracy__chart">
                <img src={CefrChart} alt="chart" />
                <div className="CefrPaper__accuracy__chart__titles">
                    <span>{t("landing.edia_algorithm")}</span>
                    <span>{t("landing.author_average")}</span>
                </div>
            </div>
        </section>
    );
};

export default AccuracyBars;
