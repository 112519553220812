import React from "react";
import { Link } from "react-router-dom";
import PasswordBlock from "./PasswordBlock";

import Envelope from "../../images/papyrus_password_send_icon.svg";

const PasswordSendEmail = () => (
    <PasswordBlock>
        <div className="PasswordEnterEmail__block__container">
            <h3 className="PasswordEnterEmail__block__container__title">
                Reset email sent
            </h3>
            <img
                src={Envelope}
                alt="envelope"
                style={{ width: "175px", margin: "32px" }}
            />
            <Link
                to="/"
                className="PasswordEnterEmail__block__container__action"
                style={{ width: "275px" }}
            >
                Back to the login page
            </Link>
        </div>
    </PasswordBlock>
);

export default PasswordSendEmail;
