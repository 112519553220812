import React from "react";
import "../../style/components/KeywordBlock.css";
import { useTranslation } from "react-i18next";

export const filterArray = { FREQUENCY: "FREQUENCY", CERTAINTY: "CERTAINTY" };

const KeywordFilter = ({ filter, setFilter }) => {
    const { t } = useTranslation();

    const setFilterClassName = button => {
        let className;
        if (filter === button) {
            className = "author-KeywordBlock__filter__button active";
        } else {
            className = "author-KeywordBlock__filter__button";
        }
        return className;
    };

    return (
        <div className="author-KeywordBlock__filter__container">
            <span className="author-KeywordBlock__filter__title">
                {t("topic.show_top_10_keywords")}
            </span>
            <div className="author-KeywordBlock__filter">
                <button
                    className={setFilterClassName(filterArray.FREQUENCY)}
                    onClick={() => setFilter(filterArray.FREQUENCY)}
                >
                    {t("topic.frequency")}
                </button>

                <button
                    className={setFilterClassName(filterArray.CERTAINTY)}
                    onClick={() => setFilter(filterArray.CERTAINTY)}
                >
                    {t("topic.certainity")}
                </button>
            </div>
        </div>
    );
};

export default KeywordFilter;
