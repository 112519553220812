import React from "react";
import BlockSection from "../Block/BlockSection";
import "../../style/components/LinguisticsBlock.css";
import { useTranslation } from "react-i18next";

const LinguisticsBlock = ({ textStatistics = {}, characterCount = 0 }) => {
    const { t } = useTranslation();

    return (
        <BlockSection
            className="author-LinguisticsBlock__section"
            title={t("linguistic.text_statistics")}
            showHelp={true}
            explanation={t("linguistic.linguistic_explanation")}
        >
            <table>
                <tbody>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.word_count")}</td>
                        <td>{textStatistics.wordCount}</td>
                    </tr>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.character_count")}</td>
                        <td>{characterCount} </td>
                    </tr>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.sentence_count")}</td>
                        <td>{textStatistics.sentenceCount}</td>
                    </tr>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.unique_words_count")}</td>
                        <td>{textStatistics.uniqueWords}</td>
                    </tr>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.token_types_ratio")}</td>
                        <td>
                            {textStatistics.ttr &&
                                textStatistics.ttr.toFixed(2)}
                        </td>
                    </tr>
                    <tr className="author-LinguisticsBlock__container">
                        <td>{t("linguistic.mtld")}</td>
                        <td>
                            {textStatistics.mtld &&
                                textStatistics.mtld.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </BlockSection>
    );
};

export default LinguisticsBlock;
